import React from 'react'
import image from '../../assets/images/headless-cms/undraw_Questions_re_1fy7.svg'


const HeadlessCmsPart2 = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image p-4">
                            <img src={image} alt="about"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Warum Headless CMS ?</h2>
                                <p>
                                    Aus dem gleichen Grund warum wir Wordpress bescheiden finden. Wir wollen
                                    Webprojekte betreiben, die resourcenschonend, schnell und günstig arbeiten. Das
                                    ist nicht nur gut für die Umwelt und den Geldbeutel, es macht auch hochgradig Sinn.
                                </p>
                                <p>
                                    Klassische Content Management Systeme basieren auf zwei Eckpfeilern. Es gibt fast immer
                                    eine Datenbank die betrieben werden muss, sowie zumeist mindestens zwei Server, wobei
                                    einer für Autoren ist und der andere für die Webseite an sich. Obendrein werden
                                    Sie pro Redakteur abgerechnet. Wir brechen mit dieser Ideologie. Das alles geht besser
                                    und wir zeigen es Dir.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default HeadlessCmsPart2;
