import React from 'react'
import image from '../../assets/images/aws-consulting/undraw_server_status_5pbv.svg'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import {Row, Col} from 'react-bootstrap'
import {Link} from 'gatsby'

const DatabaseFileSection = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={6} md={12}>
                        <div className="about-image">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>

                    <Col lg={6} md={12}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Beispiel 1: Datenbanken & File Storage</h2>
                                <p>
                                    Die Möglichkeiten Daten zu speichern sind so vielfältig wie anspruchsvoll die
                                    richtige Wahl zu treffen. Angefangen von Objektstores wie Amazon S3™, über NoSQL
                                    Datenbanken wie DynamoDB™, zu Filestores wie Amazon EFS™ und relationalen
                                    Datenbanken wie MariaDB™ oder Postgresql™.
                                </p>
                                <p>
                                    Wir helfen sowohl bei der Migration einer relationalen Datenbank von onPremise zu
                                    onCloud, als auch bei der Analyse einer Storagetechnologie für ein zu entwickelndes
                                    Softwareprodukt. Die jeweiligen Vorteile unterscheiden sich stark und die richtige
                                    Wahl des Speichermediums beeinflusst die Qualität des Produkts maßgeblich. Durch
                                    unsere gute AWS Beratung finden wir für Dich die optimale Herangehensweise.
                                </p>

                                {/*<ul className="about-list">
                                    <li>
                                        <i className="okicon-check"></i>
                                        Big Data
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Visualization
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Warehousing
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Management
                                        <img src={shape2} alt="about" />
                                    </li>
                                </ul>*/}

                                <Link to="/ueber-uns/" className="default-btn">
                                    <i className="okicon-arrow-right"/>
                                    Mehr über uns
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="about"/>
            </div>
        </section>
    )
}

export default DatabaseFileSection;
