import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import AboutSection from '../components/AboutUs/AboutSection'
import WhyChooseUs from "../components/AboutUs/WhyChooseUs";
import SEO from '../components/App/SEO'
import okaycloudLogo from '../assets/images/okaycloud_logo.svg'
import {graphql} from "gatsby";

const UeberUns = ({data, location: {origin}}) => {
    const siteUrl = data.site.siteMetadata.siteUrl;
    return (
        <Layout>
            <SEO title={"Über uns. Wer wir sind und was wir machen."}
                 description={"okaycloud sind Experten für Cloud Technologie aus Berlin. Wir konzipieren und entwickeln cloudbasierte Softwareprojekte. Die Cloud ist unser Zuhause."}
                 image={"/images/og/ueber-uns-og.png"}
            >

                <script type="application/ld+json">{`{
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "de",
                    "addressLocality": "Berlin, Germany",
                    "postalCode": "10245",
                    "streetAddress": "Dirschauer Str. 12"
                  },
                  "email": "info@okaycloud.de",
                  "name": "okaycloud UG",
                  "url": "${siteUrl}",
                  "logo": "${origin}${okaycloudLogo}",
                  "telephone": "+49 (0)30 340 4567 5"
                }`}
                </script>
            </SEO>
            <Navbar/>
            <AboutSection/>
            <WhyChooseUs/>
            <Footer/>
        </Layout>
    )
}

export default UeberUns;

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        siteUrl
      }
    }
}
`;

