import React from 'react'
import image from '../../assets/images/growth-hacking/persona.svg'


const GrowthHackingPart2 = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image p-4">
                            <img src={image} alt="about"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Personas im Growth Hacking</h2>
                                <p>
                                    Ein wichtiger Bestandteil ist die Definition der Persona. Sie erlaubt es für
                                    jegliche Kampagnen und AKtionen den richtigen Grundton zu treffen und hilft allen
                                    Mitarbeitern das gleiche Verständniss für das Marketing Target zu entwickeln.
                                </p>
                                <p>
                                    Dabei muss es nicht bei einer Persona bleiben. Ist Deine Zielgruppe divers und
                                    nicht auf eine Persona einzugrenzen, so ist es möglich mehrere Personas zu definieren.
                                    Auch kann man Marketing-Aktionen besser intern kommunizieren, wenn klar ist, welches
                                    Target angesprochen werden soll.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default GrowthHackingPart2;
