import React from 'react';

import AstronautIcon from '../../assets/images/cloud-development/undraw_work_together_h63l.svg'

const ClearFocus = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Klarer Fokus mit Expertise und Erfahrung</h2>
                                <p>
                                    Wir entwickeln Projekte entlang unserer Technologieexpertise, welche sich in vielen
                                    cloudbasierten Systemen bewährt hat. Wir lehnen lieber Projekte ab, als diese mit
                                    einer Technologie zu entwickeln, die wir nicht perfekt beherschen oder die aus
                                    unserer Sicht keinen Sinn machen. </p>
                                <p>
                                    Wir wollen das bestmögliche Softwareprodukt entwickeln und nutzen dafür folgende Technologien:
                                </p>
                                <ul className="about-list">
                                    <li>
                                        <i className="okicon-check"/>
                                        React
                                    </li>
                                    <li>
                                        <i className="okicon-check"/>
                                        React Native
                                    </li>
                                    <li>
                                        <i className="okicon-check"/>
                                        Java
                                    </li>
                                    <li>
                                        <i className="okicon-check"/>
                                        Spring Framework
                                    </li>
                                    <li>
                                        <i className="okicon-check"/>
                                        REST / Hateoas
                                    </li>
                                    <li>
                                        <i className="okicon-check"/>
                                        Typescript
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={AstronautIcon} alt="elefant"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClearFocus;