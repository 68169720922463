import React from 'react'
import {Link} from 'gatsby'
import sprite from '../../assets/images/icons/okaycloud-SVG-sprite.svg'
import {Container, Row, Col} from 'react-bootstrap'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-4 pt-5">
            <Container>
                <div className="section-title">
                    <h2>Experten in den Gebieten</h2>
                    <p>
                        Wir beraten ausschließlich in Gebieten mit großer Expertise. Wir werden niemals sagen 'Wir
                        können alles'. Versprochen.
                    </p>
                </div>

                <Row style={{display: "flex", flexWrap: "wrap"}}>
                    <Col lg={4} xs={12} className={"mt-5"}>
                        <div className="single-solutions-box h-100">
                            <svg>
                                <use href={sprite + "#building-1"}/>
                            </svg>
                            <h3>
                                <Link to="/aws-consulting/">
                                    Amazon AWS Consulting
                                </Link>
                            </h3>
                            <p>
                                Wir helfen Dir die Power von Amazon AWS Services in Deinem Unternehmen zu nutzen.
                            </p>
                        </div>
                    </Col>

                    <Col lg={4} xs={12} className={"mt-5"}>
                        <div className="single-solutions-box h-100">
                            <svg>
                                <use href={sprite + "#building"}/>
                            </svg>

                            <h3>
                                <Link to="/cloud-entwicklung/">
                                    Cloud Architekturen
                                </Link>
                            </h3>

                            <p>
                                Wir schlagen Dir die passenden Cloudarchitekturen für Deine konkrete Aufgabenstellung
                                vor.
                            </p>

                        </div>
                    </Col>

                    <Col lg={4} xs={12} className={"mt-5"}>
                        <div className="single-solutions-box h-100">
                            <svg>
                                <use href={sprite + "#clipboard"}/>
                            </svg>

                            <h3>
                                <Link to="/cloud-consulting/">
                                    Cloud Consulting / SaaS
                                </Link>
                            </h3>

                            <p>
                                Du willst ein neues Produkt als Software as a Service bereitstellen? Wir helfen Dir
                                dabei.
                            </p>

                        </div>
                    </Col>
                </Row>
                <Row style={{display: "flex", flexWrap: "wrap"}}>
                    <Col lg={4} xs={12} className={"mt-5"}>
                        <div className="single-solutions-box h-100">
                            <svg>
                                <use href={sprite + "#verified-1"}/>
                            </svg>
                            <h3>
                                <Link to="/cloud-entwicklung/">
                                    Entwickler Produktivität
                                </Link>
                            </h3>
                            <p>
                                Du willst Deine Entwickler mit den besten Cloudtools ausstatten? Wir zeigen Ansätze aus
                                eigener Erfahrung.
                            </p>

                        </div>
                    </Col>

                    <Col lg={4} xs={12} className={"mt-5"}>
                        <div className="single-solutions-box h-100">
                            <svg>
                                <use href={sprite + "#verified"}/>
                            </svg>

                            <h3>
                                <Link to="/poc-erstellung/">
                                    Proof-of-Concept Erstellung
                                </Link>
                            </h3>

                            <p>
                                Wir erstellen für Dich POCs für B2B oder B2C SaaS Lösungen mit innovativer und
                                kostenoptimierter Architektur.
                            </p>

                        </div>
                    </Col>

                    <Col lg={4} xs={12} className={"mt-5"}>
                        <div className="single-solutions-box h-100">
                            <svg>
                                <use href={sprite + "#upload"}/>
                            </svg>

                            <h3>
                                <Link to="/cloud-consulting/">
                                    Cloud Migration
                                </Link>
                            </h3>

                            <p>
                                Mit eigenen Spezialisten helfen wir Dir Deine onPremise Enterprise Software in die
                                Cloud zu portieren.
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OurSolutions;
