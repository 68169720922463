import React from 'react'
import security from '../../assets/images/saas-concepts/undraw_security_o890.svg'
import shape1 from '../../assets/images/shape/circle-shape1.png'

const SecuritySection = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={security} alt="about"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Wichtiger denn je: Software-as-a-Service Sicherheit</h2>
                                <p>
                                    Wenn die ganze Welt auf Deine SaaS Anwendung zugreifen kann, dann ist die Gefahr von
                                    Angriffsversuchen deutlich höher als bei Inhouse Software. Du hast ausserdem
                                    Herausforderungen wie Social Logins oder sicheres Payment auf der Agenda. Wir helfen
                                    Dir diese zu meistern und transferieren das Wissen auf Deine Entwickler.
                                </p>
                                <p>
                                    Schon das Verstehen der verschiedenen OAuth2 Flows und dem zusammenhängenden OpenID
                                    Connect Protokoll benötigt Wochen der Einarbeitung für jemanden der zum ersten Mal
                                    mit SaaS Software konfrontiert wird. Die Art der Speicherung von Nutzer- und
                                    Zahlungsdaten ist ausserdem ein Feld, was besser nicht dem Zufall überlassen wird.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="about"/>
            </div>
        </section>
    )
}

export default SecuritySection;
