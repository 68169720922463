import React from 'react';
import {Link} from "gatsby";

const AllTagsWidget = ({tagsCountsArray}) => {
    return (
        <div className="widget widget_tag_cloud">
            <h3 className="widget-title">All Tags</h3>
            <div className="tagcloud">
                {tagsCountsArray.map((tagCount) => (
                    <Link key={tagCount.tag} to={'/blog/tag/'+tagCount.tag.toLowerCase()+"/"} className={"neutral-link-optional-color"}>
                        {tagCount.tag} <span className="tag-link-count">({tagCount.posts})</span>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AllTagsWidget;
