import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlogRoll from '../components/BlogContent/BlogRoll'
import MainBanner from "../components/common/MainBanner";
import {graphql} from "gatsby";
import {Helmet} from 'react-helmet';

const PaginatedBlogRoll = ({data, pageContext}) => {
    const {edges: posts} = data.allMarkdownRemark;
    const siteUrl = data.site.siteMetadata.siteUrl;
    const ogImage = siteUrl + "/images/og/blog-og.png";
    const blogDescription = "Das okaycloud Blog gibt Dir einen Einblick mit was für Technologien wir uns täglich beschäftigen und vermittelt darüber hinaus wertvolle Tipps und Tricks."
    const blogTitle = "The okaycloud Blog"
    return (
        <Layout>
            <Navbar/>
            <Helmet>
                <title>{blogTitle}</title>
                <meta name={"description"} content={blogDescription}/>
                <meta name={"image"} content={ogImage}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={blogTitle}/>}
                <meta name="twitter:description" content={blogDescription}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta property={"og:description"} content={blogDescription}/>
                <meta property={"og:image"} content={ogImage}/>
                <meta property={"og:title"} content={blogTitle}/>
            </Helmet>
            <MainBanner title={blogTitle}
                        subtitle={"technology wisdom for free but english only"}
                        withCallToAction={false}/>
            <BlogRoll posts={posts} numPages={pageContext.numPages} currentPage={pageContext.currentPage}/>
            <Footer/>
        </Layout>
    );
};

export default PaginatedBlogRoll;

export const pageQuery = graphql`
  query PaginatedBlogPosts($skip: Int!, $limit: Int!) {
     site {
        siteMetadata {
            siteUrl
        }
     }
     allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      filter: { frontmatter: {disabled: {eq: false} } }
      skip: $skip) 
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 400)
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                description
                tags
                category
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 750) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                authorFull {
                    email
                    name
                    shortbio
                    title
                    authorimage {
                        childImageSharp {
                            fixed(width: 50) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                socialmediaimage {
                  childImageSharp {
                    fluid(maxWidth:800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    resize(width: 1200) {
                      src
                    }
                  }
                }
              }
            }
        }
     }
  }
`
