import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/App/Layout'
import BlogDetailsContent from "../components/BlogContent/BlogDetailsContent";
import Navbar from "../components/App/Navbar";
import {Helmet} from 'react-helmet';
import okaycloudLogo from '../assets/images/okaycloud_logo.svg'
import Footer from "../components/App/Footer";


const SingleBlogPost = ({data, pageContext, location: {origin}}) => {
    const {markdownRemark: post, site: {siteMetadata: {siteUrl}}} = data;
    const fullUrl = siteUrl + post.fields.slug;
    return (
        <Layout>
            <Helmet titleTemplate={`%s`}>
                <title>{post.frontmatter.htmltitle}</title>
                <meta name={"description"} content={post.frontmatter.description}/>
                <meta name={"image"} content={siteUrl + post.frontmatter.socialmediaimage.childImageSharp.resize.src}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={post.frontmatter.title}/>
                <meta name="twitter:description" content={post.frontmatter.description}/>
                <meta name="twitter:image" content={siteUrl + post.frontmatter.socialmediaimage.childImageSharp.resize.src}/>

                <meta property={"og:description"} content={post.frontmatter.description}/>
                <meta property={"og:image"} content={siteUrl + post.frontmatter.socialmediaimage.childImageSharp.resize.src}/>
                <meta property={"og:title"} content={post.frontmatter.title}/>

                <script type="application/ld+json">{`
                    { 
                         "@context": "https://schema.org", 
                         "@type": "BlogPosting",
                         "headline": "${post.frontmatter.title}",
                         "image": "${origin}${post.frontmatter.socialmediaimage.childImageSharp.resize.src}",
                         "editor": "${post.frontmatter.authorFull.name}", 
                         "genre": "${post.frontmatter.title}", 
                         "keywords": "${post.frontmatter.tags}", 
                         "wordcount": "${post.wordCount.words}",
                         "publisher": {
                            "@type": "Organization",
                            "name": "okaycloud UG",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${origin}${okaycloudLogo}"
                            }
                         },
                         "url": "${fullUrl}",
                         "datePublished": "${post.frontmatter.jsonld_date}",
                         "dateCreated": "${post.frontmatter.jsonld_date}",
                         "description": "${post.frontmatter.description}",
                         "articleBody": "${post.jsonld.replaceAll("\"", "'")}",
                         "author": {
                            "@type": "Person",
                            "name": "${post.frontmatter.authorFull.name}"
                         }
                    }
                `}</script>

            </Helmet>
            <Navbar/>
            <BlogDetailsContent
                tagCountsArray={pageContext.tagCountsArray}
                categoryCountsArray={pageContext.categoryCountsArray}
                withInfo={false}
                category={post.frontmatter.category}
                withSidebar={true}
                htmlContent={post.html}
                author={post.frontmatter.authorFull}
                description={post.frontmatter.description}
                tags={post.frontmatter.tags}
                image={post.frontmatter.socialmediaimage.childImageSharp}
                date={post.frontmatter.date}
                title={post.frontmatter.title}
                url={fullUrl}/>
            <Footer/>
        </Layout>
    )
};

export default SingleBlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
        siteMetadata {
            siteUrl
        }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 400)
      jsonld:excerpt(format: PLAIN, pruneLength: 4000)
      wordCount {
         words
      }
      internal {
        content
      }
      html
       fields {
        slug
      }
      frontmatter {
        date: date(formatString: "DD.MM.YYYY · HH:mm")
        jsonld_date: date(formatString: "YYYY-MM-DD")
        htmltitle
        title
        description
        tags
        category
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        authorFull {
            email
            name
            shortbio
            title
            authorimage {
                childImageSharp {
                    fixed(width: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
        socialmediaimage {
          childImageSharp {
            fluid(maxWidth:800) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
            }
          }
        }
      }
    }
  }
`
