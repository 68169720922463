import React from 'react'
import image from '../../assets/images/headless-cms/selection.svg'
import {Row, Col} from 'react-bootstrap'

const AutorenSection = () => {
    return (
        <section className="about-area pb-100 pt-70">
            <div className="container-fluid">
                <Row className="row align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Auswahl des Headless Content Management Systems</h2>
                                <p>
                                    Wir arbeiten sowohl mit Open Source Headless CMS Systemen wie NetlifyCMS, als auch
                                    mit kommerziellen Anbietern wie Sanity. Dabei achten wir aber immer darauf, das das Jamstack
                                    Prinzip nicht verletzt wird. Deine Bedürfnisse entscheiden welches System wir nehmen.
                                    Wir sind neutral, denn wir verdienen nicht an Autoren- oder Serverlizenzen. Es gibt
                                    ja keine.
                                </p>
                                <p>
                                    Zusammen mit Euch erarbeiten wir das Konzept. Wir zeigen was möglich ist und setzen
                                    das Konzept schnell und präzise um.
                                </p>

                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image p-4">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default AutorenSection;
