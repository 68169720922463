import React from 'react';
import {useRecoilState} from 'recoil'
import {collapsedState} from '../../utils/recoil-atoms'
import logo from "../../assets/images/okaycloud_logo.svg"
import {Link} from 'gatsby';

const Navbar = ({showMenu = true}) => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo"/>
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"/>
                                <span className="icon-bar middle-bar"/>
                                <span className="icon-bar bottom-bar"/>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                {showMenu &&
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Digital Marketing <i className='okicon-chevron-down'
                                                           style={{fontSize: "10px", marginLeft: "1px"}}/>
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="/jamstack-webseiten/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Jamstack Webseiten
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    to="/seo/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    SEO / SEM
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    to="/growth-hacking/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Growth Hacking
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/headless-cms/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Headless CMS
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Cloud Technologie <i className='okicon-chevron-down'
                                                        style={{fontSize: "10px", marginLeft: "1px"}}/>
                                        </Link>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="/aws-consulting/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Amazon AWS Consulting
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    to="/cloud-entwicklung/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Cloud Entwicklung
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    to="/cloud-consulting/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Cloud Consulting
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    to="/poc-erstellung/"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    POC Erstellung
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/ueber-uns/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Über uns
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/blog/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Blog
                                        </Link>
                                    </li>
                                </ul>
                                }
                                {!showMenu && <ul className="navbar-nav"/>}

                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        {showMenu &&
                                        <Link
                                            to="/kontakt/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="okicon-arrow-right"/> Kontakt
                                        </Link>
                                        }

                                        {!showMenu &&
                                        <Link
                                            to="/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="okicon-arrow-right"/> zur Homepage
                                        </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
