import React from 'react';
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from '../components/common/MainBanner';
import OurSolutions from '../components/cloud-development/OurSolutions';
import ContractDevelopment from '../components/cloud-development/ContractDevelopment';
import ClearFocus from '../components/cloud-development/ClearFocus';
import Navbar from "../components/App/Navbar";
import SEO from '../components/App/SEO'


const CloudEntwicklung = () => {
    return (
        <Layout>
            <SEO title={"Cloud Entwicklung und Architektur aus Be"}
                 description={"Wir entwickeln Cloudprojekte entlang unserer Technologieexpertise, welche sich in vielen cloudbasierten Systemen bewährt hat."}
                 image={"/images/og/cloud-development-og.png"}
            />
            <Navbar/>
            <MainBanner title="Cloud Entwicklung und Architektur"
                        image="cloud-development/undraw_work_together_h63l.svg"/>
            <OurSolutions/>
            <ContractDevelopment/>
            <ClearFocus/>
            <Footer/>
        </Layout>
    );
}

export default CloudEntwicklung;
