import React from 'react';
import {Link} from "gatsby";

const BlogPostMetaArea = ({category, date}) => {
    return (
        <div className="entry-meta">
            <ul>
                <li>
                    <i className='okicon-folder'/>
                    <span>Category</span>
                    <Link className={"neutral-link-optional-color"} to="#">{category}</Link>
                </li>
                <li>
                    <i className='okicon-eye'/>
                    <span>Views</span>
                    <Link className={"neutral-link-optional-color"} to="#">
                        TBD
                    </Link>
                </li>
                <li>
                    <i className='okicon-calendar'/>
                    <span>Created</span>
                    <Link className={"neutral-link-optional-color"} to="#">
                        {date}
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default BlogPostMetaArea;
