import React from 'react'
import {Link} from 'gatsby'
import start1 from '../../assets/images/index/undraw_business_deal_cpi9.svg'
import shape1 from '../../assets/images/shape/circle-shape1.png'


const StartProject = () => {
    return (
        <section className="project-start-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <img src={start1} alt="banner"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>Du willst mehr über unsere Herangehensweise zu erfolgreichen Cloud Projekten
                                erfahren?</h2>
                            <p>
                                Keine Webseite wird jemals alle Fragen rund um Dein Softwareprojekt beantworten können.
                                Kontaktiere uns einfach und wir sprechen über das Projekt. Vielleicht werden wir
                                sagen, dass wir für das angestrebte Projekt nicht die Richigen sind. Wahrscheinlich ist
                                aber das wir Dir schon erste konkrete Lösungsideen präsentieren können.
                            </p>
                            <Link to="/kontakt/" className="default-btn">
                                <i className="okicon-arrow-right"/> Kontaktiere uns
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner"/>
            </div>
        </section>
    )
}

export default StartProject
