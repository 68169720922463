import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/seo/undraw_Search_re_x5gq.svg'
import {Row, Col} from 'react-bootstrap'

const SeoIntro = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Search Engine Marketing und SEO</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>SEO und SEM sind Pflicht für eine gut monetarisierende Webseite</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Falls Ihr Eure bestehende Webseite optimieren wollt, so analyisieren wir mit
                                    verschiedenen Tools die Schwächen der aktuellen Webseite. Am Ende bekommt Ihr einen
                                    Report, anhand dessen wir die Strategie und die Roadmap festlegen. Gemeinsam
                                    werden wir den Weg zu mehr Besuchern und besserer Konvertierung finden.
                                </p>
                                <p>
                                    Bei einem komplett Relaunch werden wir natürlich die Basis von SEO schon bei der
                                    Erstellung der Webseite bedenken, jedoch ist auch hier eine konkrete gemeinsame Planung
                                    nötig, denn niemand kennt Dein Business besser als Du. Und auch wir kennen nicht alle
                                    Keywords aus jeder Vertikalen.
                                </p>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image p-4">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default SeoIntro
