import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/headless-cms/undraw_content_team_3epn.svg'
import {Row, Col} from 'react-bootstrap'

const StaticSiteGeneratorSection = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">NetlifyCMS, Contentful and others</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Headless CMS - Die Zukunft im Content Management</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Es gibt einen riesigen Markt an Dienstleistern, die teure CMS Projekte an Kunden
                                    verkaufen. Unser Weg, mittels eines Headless CMS, Content in die Webseite zu bringen
                                    ist eleganter, günstiger und optimierter.
                                </p>
                                <p>
                                    Dabei setzen wir auf eine einfache Authoring-Oberfläche, die statische HTML Seiten,
                                    Google-optimiert, ausliefert. Und bei uns brauchst Du keine Server oder
                                    Authorenlizenzen. Das überlassen wir anderen Dienstleistern.
                                </p>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image p-4">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default StaticSiteGeneratorSection
