import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import JamstackIntro from "../components/jamstack-webseiten/JamstackIntro";
import SEO from '../components/App/SEO'
import MoreThanBeautifulImages from "../components/jamstack-webseiten/MoreThanBeautifulImages";
import PerfectDeployment from "../components/jamstack-webseiten/PerfectDeployment";

const PocCreation = () => {
    return (
        <Layout>
            <SEO title={"Bude der Woche: Stilsicheres Nest in Kreuzberg"}
                 description={"Dieses Liebhaberstück inmitten von Kreuzberg weiss zu überzeugen. Von A-Z durchdacht und stylisch."}
                 image={"/images/og/linkedin_ch.png"}
            />
            <Navbar/>
            <JamstackIntro/>
            <MoreThanBeautifulImages/>
            <PerfectDeployment/>
            <Footer/>
        </Layout>
    )
}


export default PocCreation
