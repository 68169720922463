import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'

const BlogCard = ({post, grid="col-lg-4 col-md-6"}) => {
    return (
        <div className={grid}>
            <div className="single-blog-post bg-fffbf5">
                <div className="post-image">
                    <Link to={post.fields.slug}>
                        <Img fluid={post.frontmatter.featuredimage.childImageSharp.fluid}/>
                    </Link>
                </div>

                <div className="post-content">
                    <ul className="post-meta d-flex justify-content-between align-items-center">
                        <li>
                            <div className="post-author d-flex align-items-center">
                                {
                                    post.frontmatter.authorFull.authorimage &&
                                    <Img style={{marginRight: "15px"}} className={"rounded-circle"} fixed={post.frontmatter.authorFull.authorimage.childImageSharp.fixed}/>
                                }
                                <span>{post.frontmatter.authorFull.name}</span>
                            </div>
                        </li>
                        <li>
                            <i className='okicon-calendar'/> {post.frontmatter.date}
                        </li>
                    </ul>
                    <h3>
                        <Link to={post.fields.slug} className={"neutral-link-optional-color"}>
                            {post.frontmatter.title}
                        </Link>
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default BlogCard