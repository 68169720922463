import React from 'react'
import image from '../../assets/images/aws-consulting/undraw_web_search_eetr.svg'
import {Link} from 'gatsby';
import {Row, Col} from 'react-bootstrap'

const ServerlessSection = () => {
    return (
        <section className="about-area pb-100 pt-70">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Beispiel 2: Serverless Computing</h2>
                                <p>
                                    Monolithische Anwendungen gelten schon länger als problematisch. Deployments von
                                    Microservices auf EC2 brechen zwar den Monolithen auf, sind jedoch immer noch nicht
                                    das optionale Szenario in einer AWS Umgebung. Die Anforderung nur das zu bezahlen
                                    was auch konkret an Leistung angefordert wird und problemlos zu skalieren bleiben
                                    unerfüllt.
                                </p>
                                <p>
                                    Steig' mit uns in die Welt des "Serverless Computing" mit AWS Lambda™
                                    ein. Wir zeigen Dir Architekturoptionen, mit denen Du Deine Microservices
                                    nicht nur betreiben, sondern sie auch kostenoptimierend und unendlich skalierend
                                    in die Amazon Umgebung integrieren kannst. Wir zeigen Dir ausserdem wie Du mit Hilfe von Events
                                    entkoppelte Anwendungen erstellen kannst.
                                </p>

                                {/*<ul className="about-list">
                                    <li>
                                        <i className="okicon-check"></i>
                                        Big Data
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Visualization
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Warehousing
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Management
                                        <img src={shape2} alt="about" />
                                    </li>
                                </ul>*/}

                                <Link to="/ueber-uns/" className="default-btn">
                                    <i className="okicon-arrow-right"/>
                                    More About Us
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ServerlessSection;
