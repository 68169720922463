import React from 'react';
import TeamIcon from '../../assets/images/cloud-development/undraw_programming_2svr.svg'
import CircleShape from '../../assets/images/shape/circle-shape1.png'

const ContractDevelopment = () => {
    return (
        <section className="about-area pb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={TeamIcon} alt="About"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Von Auftragsentwicklung bis zur Verstärkung</h2>
                                <p>
                                    Wir entwickeln Deine Cloudsoftware als Auftragsentwicklung oder im Team mit Deinen
                                    Spezialisten. Dabei bauen wir die Cloudexpertise Ihrer Teammitglieder nach und nach
                                    aus, denn unser Ziel ist keine Abhängigkeit, sondern eine Partnerschaft bei der
                                    Entwicklung.
                                </p>
                                <p>
                                    Sprich uns an für die verschiedenen Modelle der Auftragsentwicklung. Im
                                    weiteren Verlauf siehst Du unsere Technologieexpertise. Wir bieten keine Entwicklung
                                    in Technologien an, mit denen wir nicht über Jahre Erfahrungen gesammelt haben und
                                    höchste Expertise vorweisen können. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={CircleShape} alt="Circle Shape"/>
            </div>
        </section>
    )
}

export default ContractDevelopment;
