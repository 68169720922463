import React from 'react';
import Img from "gatsby-image";

const TagDescriptionWidget = ({tag}) => {

    const {name, image, weblinks, description} = tag;

    return (
        <div className="widget widget_categories">
            <h3 className="widget-title">Mehr zu {name}</h3>
            <Img fixed={image.childImageSharp.fixed} alt={name + " Logo"}/>


            <p style={{marginTop: "20px", fontSize: "smaller"}}>
                {description}
            </p>

            <ul>
                {weblinks.map((link) => (
                    <a key={link} href={link}>
                        <li style={{wordWrap: "break-word"}}>{link}</li>
                    </a>
                ))}
            </ul>

        </div>
    );
};

export default TagDescriptionWidget;
