import React from 'react'
import image from '../../assets/images/seo/undraw_cohort_analysis_stny.svg'
import {Row, Col} from 'react-bootstrap'

const DataAnalysis = () => {
    return (
        <section className="about-area pb-100 pt-70 bg-f1f8fb">
            <div className="container-fluid">
                <Row className="row align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Erst die Analyse, dann die Handlung</h2>
                                <p>
                                    Wir analysieren alle relevanten KPIs mit marktführenden Tools im Bereich SEO
                                    und Webtraffic. Wir ziehen Schlüsse und iterieren auf Basis der Ergebnisse. Im
                                    Vergleich zum Offline-Marketing, wo Messungen teilweise schwer bis unmöglich sind,
                                    ist das Online Pendant deutlich besser messbar.
                                </p>
                                <p>
                                    Im Bereich SEO Analyse sagen wir Ihnen nicht nur welche Keywords sich lohnen zu targetieren,
                                    wir geben auch konstant Feedback wie Ihre Rankings für ausgesucht Keywords sind. Wir
                                    verknüpfen die Ergebnisse mit anderen Tools wie Google Analytics und können so
                                    datengetriebene Entscheidungen treffen. Jede Kampagne wird dabei einzeln gemessen
                                    und auf Erfolg überprüft. Bauchgefühl ist gut, Entscheidungen auf Basis von Daten
                                    sind besser.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image p-4">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default DataAnalysis;
