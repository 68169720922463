import React from 'react'
import image from '../../assets/images/growth-hacking/undraw_Plans_re_s9mf.svg'
import {Row, Col} from 'react-bootstrap'

const GrowthHackingPart3 = () => {
    return (
        <section className="about-area pb-100 pt-70">
            <div className="container-fluid">
                <Row className="row align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Beispiele von erfolgreichen Growth Hacks</h2>
                                <p>
                                    Ein einziger kreativer Growth Hack kann Deiner Unternehmung ein unglaubliches Wachstum
                                    bescheren. Es ist jedoch auch klar, dass für einen sehr erfolgreichen Hack, zahlreiche nicht
                                    erfolgreiche sehr wahrscheinlich waren. Wer sich hier entmutigen lässt, kann nicht gewinnen.
                                </p>
                                <ul className="about-list">
                                    <li style={{width: "100%"}}>
                                        <i className="okicon-check"/>
                                        Einbettung von "PS I Love You" mit einem Link zum Service von Hotmail in jeder Email.
                                    </li>
                                    <li style={{width: "100%"}}>
                                        <i className="okicon-check"/>
                                        Das Versprechen für mehr Speicher bei Dropbox für eine Empfehlung.
                                    </li>
                                    <li style={{width: "100%"}}>
                                        <i className="okicon-check"/>
                                        Airbnb früheres "Post-to-Craigslist" Feature, wo die Reichweite von Craigslist für eigene Listings genutzt wurde.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image p-4">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default GrowthHackingPart3;
