import React from 'react'
import image from '../../assets/images/seo/banner_screen.jpg'


const OnlineMarketing = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image p-4">
                            <img src={image} alt="about" width={"600px"}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Wir helfen beim Search Engine Marketing</h2>
                                <p>
                                    Search Engine Marketing umfasst eine vielzahl von Aktivitäten. Angenfangen von
                                    der Bespielung der Display Netzwerke von Google und Co. bis hin zur richtigen
                                    Audience Strategie. Remarketing ist in aller Munde aber wie genau soll es aussehen
                                    und macht es überhaupt Sinn in Deinem Kontext? Das sind die Aufgabenstellungen
                                    die uns erwarten.
                                </p>
                                <p>
                                    Wir helfen dabei natürlich auch bei der Erstellung von Bannern und Text-Ads für
                                    die verschiedenen Netzwerke. Die Einbringung von pyschologisch validen Call-to-Action
                                    Symboliken und das Aufsetzen von A/B Testinfrastrukturen ist hierbei besonders wichtig.
                                    Denn erst wer Variantentests zulässt, optimiert wirklich seine Kampagnen.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default OnlineMarketing;
