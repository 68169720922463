import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/App/Banner"
import error from '../assets/images/form/undraw_QA_engineers_dg5p.svg'
import {Helmet} from "react-helmet";

const Error = () => {
    return (
        <Layout>
            <Helmet>
                <title>{"Formularproblem"}</title>
                <meta name={"description"} content={"OhhOhh! Wir haben ein Problem mit unserem Formular. Unsere Entwickler werden sich nun darum kümmern, probiere es doch via Telefon."}/>
            </Helmet>
            <Navbar/>
            <Banner headerImage={error} mainText={"Formularproblem"} mainTitle={"OhhOhh! Wir haben ein Problem mit unserem Formular. Unsere Entwickler werden sich nun darum kümmern, probiere es doch via Telefon."}/>
            <Footer/>
        </Layout>
    )
};

export default Error
