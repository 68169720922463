import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/poc-creation/undraw_creative_team_r90h.svg'
import {Row, Col} from 'react-bootstrap'

const Idea2DevelopmentSection = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Vom Start bis zum Ziel</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Von der Idee zur Proof-of-Concept Entwicklung</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Wir erstellen Deinen Proof-of-Concept nach Deinen Vorstellungen mit unseren Experten.
                                    Dabei bringen wir jahrelange Erfahrung aus dem Bereich der Produktentwicklung mit
                                    und integrieren unser Cloudwissen in das Projekt.
                                </p>
                                <p>
                                    Profitiere auch von unserem Netzwerk bei angrenzenden Disziplinen wie
                                    Online-Marketing oder Sales-Strategien in der Cloud. Wir sind in der Lage ein
                                    multidisziplinäres Team für Deine Vision zusammen zu stellen.
                                </p>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Idea2DevelopmentSection
