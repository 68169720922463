import React from 'react'
import ReactWOW from 'react-wow'
import {Link} from 'gatsby'

const Banner = ({
                    headerImage,
                    mainText,
                    mainTitle
                }) => {

    return (
        <div className="seo-agency-banner" style={{paddingTop: "110px"}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>{mainTitle}</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    {mainText}
                                </p>

                                <div className={"d-flex border"}>
                                    <div className={"p-3"}>
                                        <i className={"okicon-bolt fa-4x"} style={{fontSize: "2em"}}/>
                                    </div>
                                    <div style={{alignSelf: "center"}}>
                                        <Link to={"/six-skills-ebook/"}>Download</Link> our free E-Book<br/>&quot;6 essential
                                        Skills for bootrstrapping a SaaS Startup&quot;
                                    </div>
                                </div>
                            </ReactWOW>

                            {/*<ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/contact" className="default-btn">
                                        <i className="okicon-arrow-right"></i>
                                        Cont(r)act us <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>*/}
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={headerImage} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
