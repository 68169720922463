import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlogRoll from '../components/BlogContent/BlogRoll'
import MainBanner from "../components/common/MainBanner";
import {graphql} from "gatsby";
import TagsSidebar from "../components/BlogContent/TagsSidebar";
import {Helmet} from "react-helmet";


const PaginatedTagBlogRoll = ({data, pageContext}) => {
    const {edges: posts} = data.allMarkdownRemark;
    const {name} = data.tagsJson;
    const siteUrl = data.site.siteMetadata.siteUrl;

    const ogImage = `${siteUrl}/images/og/blog-${name}-og.png`;

    const desc = `Der ${name} Teilbereich des okaycloud Blogs gibt Dir eine Übersicht aller Artikel über ${name}}. Regelmässig neue Tipps und Tricks erwarten Dich.`;
    const title = `okaycloud Blog | Fokus: ${name}`;
    return (
        <Layout>
            <Helmet>
                <title>{title}</title>
                <meta name={"description"} content={desc}/>
                <meta name={"image"} content={ogImage}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>}
                <meta name="twitter:description" content={desc}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta property={"og:description"} content={desc}/>
                <meta property={"og:image"} content={ogImage}/>
                <meta property={"og:title"} content={title}/>
            </Helmet>
            <Navbar/>
            <MainBanner title={"okaycloud "+name+" Blog"}
                        subtitle={"More knowledge on specific topics"}
                        withCallToAction={false}/>

            <section className="blog-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <TagsSidebar tag={data.tagsJson}/>
                        </div>
                        <div className="col-lg-9 col-md-12">
                            <BlogRoll cardGrid={"col-lg-6 col-md-6"}
                                      posts={posts} numPages={pageContext.numPages}
                                      currentPage={pageContext.currentPage}/>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </Layout>
    );
};

export default PaginatedTagBlogRoll;

export const pageQuery = graphql`
  query PaginatedTagPosts($skip: Int!, $limit: Int!, $tag: String!) {
     site {
        siteMetadata {
            siteUrl
        }
     }
     tagsJson(name: {eq: $tag}) {
        name 
        image {
            childImageSharp {
                fixed(width: 150) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        description
        weblinks
        services
     }
        
     allMarkdownRemark(
      filter: {frontmatter: {tags: {eq: $tag}, disabled: {eq: false}}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip) 
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 400)
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                description
                tags
                category
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 750) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                authorFull {
                    email
                    name
                    shortbio
                    title
                    authorimage {
                        childImageSharp {
                            fixed(width: 50) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                socialmediaimage {
                  childImageSharp {
                    fluid(maxWidth:800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    resize(width: 1200) {
                      src
                    }
                  }
                }
              }
            }
        }
     }
  }
`
