import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'

const BlogSection = ({lastPosts}) => {
    return (
        <section className="blog-area pt-4 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">The okaycloud Blog</span>
                    <h2>Unsere aktuellen Blog Artikel</h2>
                    <p>Unsere hervorragenden technischen Artikel in englischer Sprache.</p>
                </div>

                <div className="row">
                    {lastPosts.map(({node: post}) => (
                        <div key={post.id} className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image">
                                    <Link to={post.fields.slug}>
                                        <Img fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                                             alt={`Blog Image ${post.frontmatter.title}`}/>
                                    </Link>
                                </div>

                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center">
                                                <Img
                                                    fixed={post.frontmatter.authorFull.authorimage.childImageSharp.fixed}
                                                    alt={post.frontmatter.authorFull.name + " Portrait"}
                                                    className={"rounded-circle"}/>
                                                <span>{post.frontmatter.authorFull.name}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i className='okicon-calendar'/> {post.frontmatter.date}
                                        </li>
                                    </ul>
                                    <h3>
                                        <Link to={post.fields.slug}>
                                            {post.frontmatter.title}
                                        </Link>
                                    </h3>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>

                <Link to="/blog/" className="default-btn">
                    <i className="okicon-arrow-right"/> Alle Artikel
                </Link>
            </div>
        </section>
    );
};

export default BlogSection;
