import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import GrowthHackingIntro from "../components/growth-hacking/GrowthHackingIntro";
import GrowthHackingPart2 from "../components/growth-hacking/GrowthHackingPart2";
import GrowthHackingPart3 from "../components/growth-hacking/GrowthHackingPart3";

const PocCreation = () => {
    return (
        <Layout>
            <SEO title={"Growth Hacking - Kreativ zum Wachstum"}
                 description={"Wir wollen auf digitalem Weg Dein Wachstun beschleunigen. Wir schlagen kreative Wege vor dieses Ziel zu erreichen. "}
                 image={"/images/og/growth-hacking-og.png"}
            />
            <Navbar/>
            <GrowthHackingIntro/>
            <GrowthHackingPart2/>
            <GrowthHackingPart3/>
            <Footer/>
        </Layout>
    )
}


export default PocCreation
