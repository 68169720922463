import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/App/PageBanner'
import Footer from "../components/App/Footer"
import {Link} from 'gatsby';
import SEO from '../components/App/SEO'

const Impressum = () => {
    return (
        <Layout>
            <SEO title={"Impressum"}
                 description={"Alle relevanten Angaben die Teil des Impressum sein müssen wie Name des Geschäftsführers, Adresse der Firma und steuerrelevante Daten."}
                 image={"/images/og/cloud-development.png"}
            />
            <Navbar/>
            <PageBanner
                pageTitle={"Impressum"}
                homePageText="Home"
                homePageUrl="/"
                activePageText={"Impressum"}
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <p><i>Das Impressum wurde zum letzten Mal am 5. Januar 2020 aktualisiert.</i></p>
                                <p>
                                    <b>okaycloud UG</b><br/>
                                    Kühnehof 41<br/>
                                    49074 Osnabrück
                                </p>

                                <p>Vertretungsberechtiger Geschäftsführer: Marc Logemann</p>

                                <p>
                                    Telefon: +49 30 340 45675<br/>
                                    EMail: info@okaycloud.de<br/>
                                </p>

                                <p>
                                    Registergericht: Amtsgericht Charlottenburg<br/>
                                    Registernummer: HRB 198064<br/>
                                    Umsatzsteuer-IdentNr.: DE320097119
                                </p>

                                <p>
                                    Inhaltlich Verantwortlicher ist: Marc Logemann (Anschrift s.o.)
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li className="active">
                                            <Link to="/impressum/">
                                                Impressum
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/datenschutz/">
                                                Datenschutz
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </Layout>
    )
}

export default Impressum;
