import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/App/PageBanner'
import Footer from "../components/App/Footer"
import {Link} from 'gatsby';
import SEO from '../components/App/SEO'

const Datenschutz = () => {
    return (
        <Layout>
            <SEO title={"Datenschutz"}
                 description={"Datenschutz ist uns ein wichtiges Anliegen und wir informieren Dich ausgiebig. Wir sind DSGVO komform und dokumentieren für Sie alle relevanten Datensammlungen"}
                 image={"/images/og/poc-creation.png"}
            />
            <Navbar/>
            <PageBanner
                pageTitle={"Datenschutz"}
                homePageText="Home"
                homePageUrl="/"
                activePageText={"Datenschutz"}
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                                <p><i>Diese Datenschutzerklärung wurde zum letzten Mal am 1. Januar 2020 aktualisiert.</i></p>
                                <blockquote className="blockquote">
                                    <p>
                                        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der
                                        Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb
                                        unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und
                                        Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile
                                        auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf
                                        die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder
                                        „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
                                        Datenschutzgrundverordnung (DSGVO).
                                    </p>
                                </blockquote>
                                <h3>Verantwortlicher</h3>
                                <p>
                                    Logemann, Marc / okaycloud UG<br/>
                                    Dirschauer Str. 12<br/>
                                    10245 Berlin<br/>
                                    Deutschland<br/>
                                </p>

                                <p>
                                    E-Mailadresse: info@okaycloud.de<br/>
                                    Geschäftsführer: Logemann, Marc<br/>
                                    Link zum Impressum: <Link to='/impressum/'>Link</Link><br/>
                                </p>


                                <h3>Arten der verarbeiteten Daten</h3>
                                <ul>
                                    <li>Bestandsdaten (z.B., Namen, Adressen).</li>
                                    <li>Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
                                    <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
                                    <li>Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
                                        Zugriffszeiten).
                                    </li>
                                    <li>Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</li>
                                </ul>
                                <h3>Kategorien betroffener Personen</h3>
                                <p>
                                    Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen
                                    Personen zusammenfassend auch als „Nutzer“).
                                </p>
                                <h3>Zweck der Verarbeitung</h3>
                                <ul>
                                    <li>Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.</li>
                                    <li>Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</li>
                                    <li>Sicherheitsmaßnahmen.</li>
                                    <li>Reichweitenmessung/Marketing</li>
                                </ul>
                                <h3>Verwendete Begrifflichkeiten</h3>
                                <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte
                                    oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen;
                                    als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
                                    insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                                    Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
                                    oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der
                                    physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
                                    oder sozialen Identität dieser natürlichen Person sind.</p>

                                <p>„Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte
                                    Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
                                    Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.</p>

                                <p>„Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die
                                    personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr
                                    einer spezifischen betroffenen Person zugeordnet werden können, sofern diese
                                    zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
                                    organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
                                    personenbezogenen Daten nicht einer identifizierten oder identifizierbaren
                                    natürlichen Person zugewiesen werden.</p>

                                <p>„Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die
                                    darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte
                                    persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
                                    insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit,
                                    persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
                                    Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.</p>

                                <p>Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde,
                                    Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
                                    Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
                                    bezeichnet.</p>

                                <p>„Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung
                                    oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
                                    verarbeitet.
                                </p>
                                <h3>Maßgebliche Rechtsgrundlagen</h3>
                                <p>
                                    Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer
                                    Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der Datenschutzerklärung
                                    nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung von
                                    Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für
                                    die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher
                                    Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
                                    Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
                                    Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die
                                    Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f
                                    DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
                                    einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten
                                    erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
                                </p>
                                <h3>Sicherheitsmaßnahmen</h3>
                                <p>
                                    Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der
                                    Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der
                                    Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und
                                    Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete
                                    technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                                    Schutzniveau zu gewährleisten.
                                </p>
                                <p>
                                    Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität
                                    und Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als
                                    auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
                                    Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
                                    eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf
                                    Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
                                    personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware,
                                    Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch
                                    Technikgestaltung und durch datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).
                                </p>
                                <h3>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>

                                <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und
                                    Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln
                                    oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage
                                    einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie
                                    an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung
                                    erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies
                                    vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
                                    Beauftragten, Webhostern, etc.).
                                </p>

                                <p>Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog.
                                    „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art.
                                    28 DSGVO.
                                </p>

                                <h3>Übermittlungen in Drittländer</h3>
                                <p>
                                    Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder
                                    des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der
                                    Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten
                                    an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
                                    (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer
                                    rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen
                                    geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten
                                    oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen
                                    Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt
                                    z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung
                                    eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy
                                    Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
                                    Verpflichtungen (so genannte „Standardvertragsklauseln“).
                                </p>
                                <h3>Rechte der betroffenen Personen</h3>
                                <p>
                                    Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten
                                    verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
                                    Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.
                                </p>
                                <p>
                                    Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie
                                    betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten
                                    zu verlangen.
                                </p>
                                <p>
                                    Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende
                                    Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18
                                    DSGVO eine Einschränkung der Verarbeitung der Daten zu verlangen.
                                </p>
                                <p>
                                    Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns
                                    bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten und deren
                                    Übermittlung an andere Verantwortliche zu fordern.
                                </p>
                                <p>
                                    Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen
                                    Aufsichtsbehörde einzureichen.
                                </p>
                                <h3>Widerrufsrecht</h3>
                                <p>
                                    Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung
                                    für die Zukunft zu widerrufen
                                </p>
                                <h3>Widerspruchsrecht</h3>
                                <p>
                                    Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des
                                    Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die
                                    Verarbeitung für Zwecke der Direktwerbung erfolgen.
                                </p>
                                <h3>Cookies und Widerspruchsrecht bei Direktwerbung</h3>
                                <p>
                                    Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer
                                    gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben
                                    gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer (bzw.
                                    dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch
                                    innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw.
                                    „Session-Cookies“ oder „transiente Cookies“, werden Cookies bezeichnet, die gelöscht
                                    werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt.
                                    In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop
                                    oder ein Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden
                                    Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So
                                    kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren
                                    Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer
                                    gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet
                                    werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen
                                    Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden
                                    (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party
                                    Cookies“).
                                </p>

                                <p>
                                    Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen
                                    unserer Datenschutzerklärung auf.
                                </p>

                                <p>
                                    Falls die Nutzer nicht möchten, dass Cookies auf Ihrem Rechner gespeichert werden,
                                    werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres
                                    Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des
                                    Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
                                    Funktionseinschränkungen dieses Onlineangebotes führen.
                                </p>

                                <p>
                                    Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing
                                    eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des
                                    Trackings, über die US-amerikanische Seite http://www.aboutads.info/choices/ oder
                                    die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Des Weiteren kann die
                                    Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers
                                    erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
                                    dieses Onlineangebotes genutzt werden können.
                                </p>
                                <h3>Löschung von Daten</h3>
                                <p>
                                    Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO
                                    gelöscht
                                    oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser
                                    Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten
                                    gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
                                    Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
                                    nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
                                    erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden
                                    gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
                                    handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
                                </p>

                                <p>
                                    Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für
                                    10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher,
                                    Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung
                                    relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB
                                    (Handelsbriefe).
                                </p>

                                <p>
                                    Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7
                                    J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege,
                                    Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.), für 22 Jahre im
                                    Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang mit
                                    elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und
                                    Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und
                                    für die der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
                                </p>
                                <h3>Vertragliche Leistungen</h3>
                                <p>
                                    Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie anderer
                                    Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner (einheitlich
                                    bezeichnet als „Vertragspartner“) entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen
                                    gegenüber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die
                                    hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die
                                    Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden
                                    Vertragsverhältnis.
                                </p>

                                <p>
                                    Zu den verarbeiteten Daten gehören die Stammdaten unserer Vertragspartner (z.B.,
                                    Namen und Adressen), Kontaktdaten (z.B. E-Mailadressen und Telefonnummern) sowie
                                    Vertragsdaten (z.B., in Anspruch genommene Leistungen, Vertragsinhalte, vertragliche
                                    Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B., Bankverbindungen,
                                    Zahlungshistorie).
                                </p>

                                <p>
                                    Besondere Kategorien personenbezogener Daten verarbeiten wir grundsätzlich nicht,
                                    außer wenn diese Bestandteile einer beauftragten oder vertragsgemäßen Verarbeitung
                                    sind.
                                </p>

                                <p>
                                    Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen
                                    erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe, sofern diese für
                                    die Vertragspartner nicht evident ist, hin. Eine Offenlegung an externe Personen
                                    oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist.
                                    Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten, handeln
                                    wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben.
                                </p>

                                <p>
                                    Im Rahmen der Inanspruchnahme unserer Onlinedienste, können wir die IP-Adresse und
                                    den Zeitpunkt der jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf
                                    Grundlage unserer berechtigten Interessen, als auch der Interessen der Nutzer am
                                    Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten
                                    an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer
                                    Ansprüche gem. Art. 6 Abs. 1 lit. f. DSGVO erforderlich oder es besteht hierzu eine
                                    gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.
                                </p>

                                <p>
                                    Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder
                                    gesetzlicher Fürsorgepflichten sowie für den Umgang mit etwaigen Gewährleistungs-
                                    und vergleichbaren Pflichten nicht mehr erforderlich sind, wobei die
                                    Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im
                                    Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
                                </p>
                                <h3>Externe Zahlungsdienstleister</h3>
                                <p>
                                    Wir setzen externe Zahlungsdienstleister ein, über deren Plattformen die Nutzer und
                                    wir Zahlungstransaktionen vornehmen können (z.B., jeweils mit Link zur
                                    Datenschutzerklärung, Paypal
                                    (https://www.paypal.com/de/webapps/mpp/ua/privacy-full), Klarna
                                    (https://www.klarna.com/de/datenschutz/), Skrill
                                    (https://www.skrill.com/de/fusszeile/datenschutzrichtlinie/), Giropay
                                    (https://www.giropay.de/rechtliches/datenschutz-agb/), Visa
                                    (https://www.visa.de/datenschutz), Mastercard
                                    (https://www.mastercard.de/de-de/datenschutz.html), American Express
                                    (https://www.americanexpress.com/de/content/privacy-policy-statement.html)
                                </p>

                                <p>
                                    Im Rahmen der Erfüllung von Verträgen setzen wir die Zahlungsdienstleister auf
                                    Grundlage des Art. 6 Abs. 1 lit. b. DSGVO ein. Im Übrigen setzen wir externe
                                    Zahlungsdienstleister auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs.
                                    1 lit. f. DSGVO ein, um unseren Nutzern effektive und sichere Zahlungsmöglichkeit zu
                                    bieten.
                                </p>

                                <p>
                                    Zu den, durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten,
                                    wie z.B. der Name und die Adresse, Bankdaten, wie z.B. Kontonummern oder
                                    Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen und
                                    empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen
                                    durchzuführen. Die eingegebenen Daten werden jedoch nur durch die
                                    Zahlungsdienstleister verarbeitet und bei diesen gespeichert. D.h. wir erhalten
                                    keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich
                                    Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung. Unter Umständen
                                    werden die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien
                                    übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu
                                    verweisen wir auf die AGB und Datenschutzhinweise der Zahlungsdienstleister.
                                </p>

                                <p>
                                    Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
                                    Datenschutzhinweise
                                    der jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten,
                                    bzw. Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls
                                    zwecks weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts- und
                                    anderen Betroffenenrechten.
                                </p>
                                <h3>Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung</h3>
                                <p>
                                    Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres
                                    Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der
                                    Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der
                                    Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen
                                    sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung
                                    sind Kunden, Interessenten, Geschäftspartner und Websitebesucher betroffen. Der
                                    Zweck und unser Interesse an der Verarbeitung liegt in der Administration,
                                    Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben die der
                                    Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und
                                    Erbringung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf
                                    vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei
                                    diesen Verarbeitungstätigkeiten genannten Angaben.
                                </p>

                                <p>
                                    Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie
                                    z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und
                                    Zahlungsdienstleister.
                                </p>

                                <p>
                                    Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen
                                    Angaben zu Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks
                                    späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern
                                    wir grundsätzlich dauerhaft.
                                </p>
                                <h3>Betriebswirtschaftliche Analysen und Marktforschung</h3>
                                <p>
                                    Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Wünsche der
                                    Vertragspartner und Nutzer erkennen zu können, analysieren wir die uns vorliegenden
                                    Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc. Wir verarbeiten dabei
                                    Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten, Nutzungsdaten,
                                    Metadaten auf Grundlage des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen
                                    Personen Vertragspartner, Interessenten, Kunden, Besucher und Nutzer unseres
                                    Onlineangebotes gehören.
                                </p>

                                <p>
                                    Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen, des
                                    Marketings
                                    und der Marktforschung. Dabei können wir die Profile der registrierten Nutzer mit
                                    Angaben, z.B. zu deren in Anspruch genommenen Leistungen, berücksichtigen. Die
                                    Analysen dienen uns zur Steigerung der Nutzerfreundlichkeit, der Optimierung unseres
                                    Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns und
                                    werden nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit
                                    zusammengefassten Werten handelt.
                                </p>

                                <p>
                                    Sofern diese Analysen oder Profile personenbezogen sind, werden sie mit Kündigung
                                    der
                                    Nutzer gelöscht oder anonymisiert, sonst nach zwei Jahren ab Vertragsschluss. Im
                                    Übrigen werden die gesamtbetriebswirtschaftlichen Analysen und allgemeine
                                    Tendenzbestimmungen nach Möglichkeit anonym erstellt.
                                </p>
                                <h3>Registrierfunktion</h3>
                                <p>
                                    Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden die
                                    erforderlichen Pflichtangaben den Nutzern mitgeteilt und auf Grundlage des Art. 6
                                    Abs. 1 lit. b DSGVO zu Zwecken der Bereitstellung des Nutzerkontos verarbeitet. Zu
                                    den verarbeiteten Daten gehören insbesondere die Login-Informationen (Name, Passwort
                                    sowie eine E-Mailadresse). Die im Rahmen der Registrierung eingegebenen Daten werden
                                    für die Zwecke der Nutzung des Nutzerkontos und dessen Zwecks verwendet.
                                </p>

                                <p>
                                    Die Nutzer können über Informationen, die für deren Nutzerkonto relevant sind, wie
                                    z.B. technische Änderungen, per E-Mail informiert werden. Wenn Nutzer ihr
                                    Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das Nutzerkonto,
                                    vorbehaltlich einer gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt den
                                    Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir
                                    sind berechtigt, sämtliche während der Vertragsdauer gespeicherten Daten des Nutzers
                                    unwiederbringlich zu löschen.
                                </p>

                                <p>
                                    Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie
                                    der
                                    Nutzung des Nutzerkontos, speichern wir die IP-Adresse und den Zeitpunkt der
                                    jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer
                                    berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger
                                    unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich
                                    nicht, außer sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht
                                    hierzu besteht eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO. Die
                                    IP-Adressen werden spätestens nach 7 Tagen anonymisiert oder gelöscht.
                                </p>
                                <h3>Abruf von Profilbildern bei Gravatar</h3>
                                <p>
                                    Wir setzen innerhalb unseres Onlineangebotes und insbesondere im Blog den Dienst
                                    Gravatar der Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA,
                                    ein.
                                </p>

                                <p>
                                    Gravatar ist ein Dienst, bei dem sich Nutzer anmelden und Profilbilder und ihre
                                    E-Mailadressen hinterlegen können. Wenn Nutzer mit der jeweiligen E-Mailadresse auf
                                    anderen Onlinepräsenzen (vor allem in Blogs) Beiträge oder Kommentare hinterlassen,
                                    können so deren Profilbilder neben den Beiträgen oder Kommentaren dargestellt
                                    werden. Hierzu wird die von den Nutzern mitgeteilte E-Mailadresse an Gravatar zwecks
                                    Prüfung, ob zu ihr ein Profil gespeichert ist, verschlüsselt übermittelt. Dies ist
                                    der einzige Zweck der Übermittlung der E-Mailadresse und sie wird nicht für andere
                                    Zwecke verwendet, sondern danach gelöscht.
                                </p>

                                <p>
                                    Die Nutzung von Gravatar erfolgt auf Grundlage unserer berechtigten Interessen im
                                    Sinne des Art. 6 Abs. 1 lit. f) DSGVO, da wir mit Hilfe von Gravatar den Beitrags-
                                    und Kommentarverfassern die Möglichkeit bieten ihre Beiträge mit einem Profilbild zu
                                    personalisieren.
                                </p>

                                <p>
                                    Durch die Anzeige der Bilder bringt Gravatar die IP-Adresse der Nutzer in Erfahrung,
                                    da dies für eine Kommunikation zwischen einem Browser und einem Onlineservice
                                    notwendig ist. Nähere Informationen zur Erhebung und Nutzung der Daten durch
                                    Gravatar finden sich in den Datenschutzhinweisen von Automattic:
                                    https://automattic.com/privacy/.
                                </p>

                                <p>
                                    Wenn Nutzer nicht möchten, dass ein mit Ihrer E-Mail-Adresse bei Gravatar
                                    verknüpftes
                                    Benutzerbild in den Kommentaren erscheint, sollten Sie zum Kommentieren eine
                                    E-Mail-Adresse nutzen, welche nicht bei Gravatar hinterlegt ist. Wir weisen ferner
                                    darauf hin, dass es auch möglich ist eine anonyme oder gar keine E-Mailadresse zu
                                    verwenden, falls die Nutzer nicht wünschen, dass die eigene E-Mailadresse an
                                    Gravatar übersendet wird. Nutzer können die Übertragung der Daten komplett
                                    verhindern, indem Sie unser Kommentarsystem nicht nutzen.
                                </p>
                                <h3>Kontaktaufnahme</h3>
                                <p>
                                    Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via
                                    sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage
                                    und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen
                                    vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f. (andere
                                    Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer können in einem
                                    Customer-Relationship-Management System ("CRM System") oder vergleichbarer
                                    Anfragenorganisation gespeichert werden.
                                </p>

                                <p>
                                    Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen
                                    die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen
                                    Archivierungspflichten.
                                </p>
                                <h3>Hosting und E-Mail-Versand</h3>
                                <p>
                                    Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
                                    Zurverfügungstellung
                                    der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen,
                                    Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand,
                                    Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des
                                    Betriebs dieses Onlineangebotes einsetzen.
                                </p>

                                <p>Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten,
                                    Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von
                                    Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer
                                    berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung
                                    dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO
                                    (Abschluss Auftragsverarbeitungsvertrag).
                                </p>
                                <h3>Erhebung von Zugriffsdaten und Logfiles</h3>
                                <p>
                                    Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten
                                    Interessen
                                    im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server,
                                    auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den
                                    Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des
                                    Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
                                    Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
                                    IP-Adresse und der anfragende Provider.
                                </p>

                                <p>
                                    Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von
                                    Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert
                                    und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich
                                    ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
                                    ausgenommen.
                                </p>
                                <h3>Google Tag Manager</h3>
                                <p>Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine
                                    Oberfläche verwalten können (und so z.B. Google Analytics sowie andere
                                    Google-Marketing-Dienste in unser Onlineangebot einbinden). Der Tag Manager selbst
                                    (welches die Tags implementiert) verarbeitet keine personenbezogenen Daten der
                                    Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer wird
                                    auf die folgenden Angaben zu den Google-Diensten verwiesen. Nutzungsrichtlinien:
                                    https://www.google.com/intl/de/tagmanager/use-policy.html.
                                </p>
                                <h3>Google Analytics</h3>
                                <p>
                                    Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
                                    Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne
                                    des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics, einen Webanalysedienst der Google
                                    LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten
                                    Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der
                                    Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                                </p>

                                <p>
                                    Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
                                    Garantie, das europäische Datenschutzrecht einzuhalten
                                    (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
                                </p>

                                <p>
                                    Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres
                                    Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten
                                    innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung
                                    dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns
                                    gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme
                                    Nutzungsprofile der Nutzer erstellt werden.
                                </p>

                                <p>
                                    Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet,
                                    die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der
                                    Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                                    Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
                                    IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                                </p>

                                <p>
                                    Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten
                                    von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine
                                    entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können
                                    darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des
                                    Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch
                                    Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin
                                    herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
                                </p>

                                <p>
                                    Weitere Informationen zur Datennutzung durch Google, Einstellungs- und
                                    Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google
                                    (https://policies.google.com/technologies/ads) sowie in den Einstellungen für die
                                    Darstellung von Werbeeinblendungen durch Google
                                    (https://adssettings.google.com/authenticated).
                                </p>

                                <p>
                                    Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder
                                    anonymisiert.
                                </p>
                                <h3>Zielgruppenbildung mit Google Analytics</h3>
                                <p>
                                    Wir setzen Google Analytics in der Ausgestaltung als „Universal-Analytics“ ein.
                                    „Universal Analytics“ bezeichnet ein Verfahren von Google Analytics, bei dem die
                                    Nutzeranalyse auf Grundlage einer pseudonymen Nutzer-ID erfolgt und damit ein
                                    pseudonymes Profil des Nutzers mit Informationen aus der Nutzung verschiedener
                                    Geräten erstellt wird (sog. „Cross-Device-Tracking“).
                                </p>
                                <h3>Zielgruppenbildung mit Google Analytics</h3>
                                <p>
                                    Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles
                                    und seiner Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch
                                    ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale
                                    (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten
                                    Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog.
                                    „Remarketing-“, bzw. „Google-Analytics-Audiences“). Mit Hilfe der Remarketing
                                    Audiences möchten wir auch sicherstellen, dass unsere Anzeigen dem potentiellen
                                    Interesse der Nutzer entsprechen.
                                </p>
                                <h3>Google AdWords und Conversion-Messung</h3>
                                <p>
                                    Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
                                    Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne
                                    des Art. 6 Abs. 1 lit. f. DSGVO) die Dienste der Google LLC, 1600 Amphitheatre
                                    Parkway, Mountain View, CA 94043, USA, („Google“).
                                </p>
                                <p>
                                    Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
                                    Garantie, das europäische Datenschutzrecht einzuhalten
                                    (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
                                </p>

                                <p>
                                    Wir nutzen das Onlinemarketingverfahren Google "AdWords", um Anzeigen im
                                    Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in Videos, auf
                                    Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein mutmaßliches Interesse
                                    an den Anzeigen haben. Dies erlaubt uns Anzeigen für und innerhalb unseres
                                    Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die
                                    potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für
                                    Produkte angezeigt werden, für die er sich auf anderen Onlineangeboten interessiert
                                    hat, spricht man hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf
                                    unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist,
                                    unmittelbar durch Google ein Code von Google ausgeführt und es werden sog.
                                    (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als "Web Beacons"
                                    bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der
                                    Nutzer ein individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies
                                    können auch vergleichbare Technologien verwendet werden). In dieser Datei wird
                                    vermerkt, welche Webseiten der Nutzer aufgesucht, für welche Inhalte er sich
                                    interessiert und welche Angebote der Nutzer geklickt hat, ferner technische
                                    Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit
                                    sowie weitere Angaben zur Nutzung des Onlineangebotes.
                                </p>

                                <p>
                                    Ferner erhalten wir ein individuelles „Conversion-Cookie“. Die mit Hilfe des Cookies
                                    eingeholten Informationen dienen Google dazu, Conversion-Statistiken für uns zu
                                    erstellen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der Nutzer, die auf
                                    unsere Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag
                                    versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit
                                    denen sich Nutzer persönlich identifizieren lassen.
                                </p>

                                <p>
                                    Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym
                                    verarbeitet. D.h. Google speichert und verarbeitet z.B. nicht den Namen oder
                                    E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten cookie-bezogen
                                    innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von Google werden die
                                    Anzeigen nicht für eine konkret identifizierte Person verwaltet und angezeigt,
                                    sondern für den Cookie-Inhaber, unabhängig davon wer dieser Cookie-Inhaber ist. Dies
                                    gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne diese
                                    Pseudonymisierung zu verarbeiten. Die über die Nutzer gesammelten Informationen
                                    werden an Google übermittelt und auf Googles Servern in den USA gespeichert.
                                </p>

                                <p>
                                    Weitere Informationen zur Datennutzung durch Google, Einstellungs- und
                                    Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google
                                    (https://policies.google.com/technologies/ads) sowie in den Einstellungen für die
                                    Darstellung von Werbeeinblendungen durch Google
                                    (https://adssettings.google.com/authenticated).
                                </p>
                                <h3>Onlinepräsenzen in sozialen Medien</h3>
                                <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit
                                    den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über
                                    unsere Leistungen informieren zu können.</p>

                                <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
                                    Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer
                                    Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert
                                    werden könnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert
                                    sind, weisen wir darauf hin, dass sie sich damit verpflichten, die
                                    Datenschutzstandards der EU einzuhalten.</p>

                                <p>Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und Werbezwecke
                                    verarbeitet. So können z.B. aus dem Nutzungsverhalten und sich daraus ergebenden
                                    Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können
                                    wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
                                    Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu
                                    diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
                                    in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden.
                                    Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern
                                    verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer Mitglieder der
                                    jeweiligen Plattformen sind und bei diesen eingeloggt sind).</p>

                                <p>Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer
                                    berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation
                                    mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den
                                    jeweiligen Anbietern um eine Einwilligung in die Datenverarbeitung gebeten werden
                                    (d.h. ihr Einverständnis z.B. über das Anhaken eines Kontrollkästchens oder
                                    Bestätigung einer Schaltfläche erklären) ist die Rechtsgrundlage der Verarbeitung
                                    Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.</p>

                                <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der
                                    Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die nachfolgend verlinkten
                                    Angaben der Anbieter.</p>

                                <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen
                                    wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden
                                    können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können
                                    direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch
                                    Hilfe benötigen, dann können Sie sich an uns wenden.</p>

                                <ul>
                                    <li>Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
                                        Dublin
                                        2, Irland) - Datenschutzerklärung: https://www.facebook.com/about/privacy/,
                                        Opt-Out:
                                        https://www.facebook.com/settings?tab=ads und http://www.youronlinechoices.com,
                                        Privacy Shield:
                                        https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active.
                                    </li>

                                    <li>Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                                        94043,
                                        USA) – Datenschutzerklärung: https://policies.google.com/privacy, Opt-Out:
                                        https://adssettings.google.com/authenticated, Privacy Shield:
                                        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.
                                    </li>

                                    <li>Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) –
                                        Datenschutzerklärung/ Opt-Out: http://instagram.com/about/legal/privacy/.
                                    </li>

                                    <li>Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103,
                                        USA) - Datenschutzerklärung: https://twitter.com/de/privacy, Opt-Out:
                                        https://twitter.com/personalization, Privacy Shield:
                                        https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active.
                                    </li>

                                    <li>LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland) -
                                        Datenschutzerklärung https://www.linkedin.com/legal/privacy-policy , Opt-Out:
                                        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out, Privacy
                                        Shield:
                                        https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active.
                                    </li>

                                    <li>Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) -
                                        Datenschutzerklärung/ Opt-Out:
                                        https://privacy.xing.com/de/datenschutzerklaerung.
                                    </li>

                                </ul>
                                <h3>Einbindung von Diensten und Inhalten Dritter</h3>
                                <p>Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten
                                    Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
                                    unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder
                                    Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B.
                                    Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als
                                    “Inhalte”).</p>

                                <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der
                                    Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser
                                    senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte
                                    erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
                                    Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
                                    Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als
                                    "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
                                    "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser
                                    Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies
                                    auf dem Gerät der Nutzer gespeichert werden und unter anderem technische
                                    Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit
                                    sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit
                                    solchen Informationen aus anderen Quellen verbunden werden.
                                </p>
                                <h3>Google Fonts</h3>
                                <p>Wir binden die Schriftarten ("Google Fonts") des Anbieters Google LLC, 1600
                                    Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung:
                                    https://www.google.com/policies/privacy/, Opt-Out:
                                    https://adssettings.google.com/authenticated.
                                </p>
                                <h3>Google Maps</h3>
                                <p>Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google LLC, 1600
                                    Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den verarbeiteten Daten
                                    können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch
                                    nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer
                                    Mobilgeräte vollzogen), erhoben werden. Die Daten können in den USA verarbeitet
                                    werden. Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out:
                                    https://adssettings.google.com/authenticated.</p>

                                <p><a href={"https://datenschutz-generator.de/"}>Erstellt mit Datenschutz-Generator.de
                                    von RA Dr. Thomas Schwenke</a></p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/impressum/">
                                                Impressum
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/datenschutz/">
                                                Datenschutz
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </Layout>
    )
}

export default Datenschutz;
