import React from 'react'
import ReactWOW from 'react-wow'
import {Row, Col} from 'react-bootstrap'
import {Link} from 'gatsby'

const EbookFeature = ({image}) => {
    return (
        <div className="seo-agency-banner" style={{paddingTop: "150px"}}>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col className={"align-self-start"} lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content pt-70">
                            <span className="sub-title">Gratis E-Book</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Six essentials skills for bootstrapping a SaaS Startup</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    <b>
                                        Das Mini E-Book für alle die überlegen eine Tech-Company zu bootstrappen.
                                        Zusätlich wertvolle Tipps und Links zu Services, die unabdingbar sind für
                                        ein schlankes Startup.
                                    </b>
                                </p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/thankyou/six-skills-ebook/" className="default-btn ebook-dl-sixskills">
                                        <i className="okicon-download"/>
                                        free Download
                                        <span/>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 5, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                {image}
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default EbookFeature
