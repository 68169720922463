import React from 'react'
import showFacts from '../../assets/images/saas-concepts/showfacts.svg'
import {Row, Col} from 'react-bootstrap'

const MoreConsultingAreas = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                        <div className="how-its-work-content">
                            <h2>Weitere Beratungsfelder</h2>
                            <p>In denen wir profundes Wissen anbieten.</p>

                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Online Marketing Integration</h3>
                                    <p>
                                        Die richtigen Tools und Ansätze für ein erfolgreiches Marketing verfolgen.
                                    </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>User Aktivitäts-Tracking</h3>
                                    <p>
                                        SaaS Anwendungen dürfen keine Blackbox sein. Wir beraten wie Userverhalten
                                        trackbar gemacht wird.
                                    </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Customer Care und Supportsysteme</h3>
                                    <p>
                                        Integration von 3rd Party Systemen zu Steigerung der Kundenzufriedenheit.
                                    </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Mandanten Isolation</h3>
                                    <p>
                                        Konzepte für eine erfolgreiche Separierung von Tenants des SaaS Systems.
                                    </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Strategien der Monetarisierung</h3>
                                    <p>
                                        Die passende Strategie für das Aufstellen von Subscription Plänen finden.
                                    </p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Zero-Downtime Deployments</h3>
                                    <p>
                                        Deployments die keine Auszeiten auf Benutzerseite verursachen sind essentiell für SaaS Projekte
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="how-its-work-image">
                            <img src={showFacts} alt="choose" className={"mb-3 mb-lg-0"}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default MoreConsultingAreas