import React from 'react'
import {RecoilRoot} from 'recoil'
import GoTop from './GoTop'
import SEO from './SEO'


const Layout = ({children}) => {
    return (
        <RecoilRoot>
            <SEO/>
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50"/>
            {/*
                <!-- HUBSPOT Script -->
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/19519922.js"></script>*/}

            {/*<!-- Mailchimp Script is done via GTM, but initial discovery doesnt work with GTM, so sometime we need this on 1st setup -->
                        <script id="mcjs" dangerouslySetInnerHTML={{
                __html: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/261aa8aa542124b3fe903fcf1/c7bddd57e8399792eb6ea0586.js");`,
            }}
            ></script>*/}
        </RecoilRoot>
    )
}

export default Layout
