/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from "gatsby"

function SEO({description, meta, title, image, link, children}) {

    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            titleSuffix
            description
            image
            siteUrl
          }
        }
      }
    `);

    const myTitle = title || site.siteMetadata.title

    // determine the social images, using props or default. If props, do some intelligent URL handling
    let myImage;
    if (image) {
        if (image.startsWith('http')) {
            myImage = image;
        } else {
            myImage = site.siteMetadata.siteUrl + image;
        }
    } else {
        myImage = site.siteMetadata.image;
    }

    const myDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang: "de",
            }}
            title={myTitle}
            titleTemplate={`%s | ${site.siteMetadata.titleSuffix}`}
            link={[].concat(link)}
            meta={[
                {
                    property: `og:title`,
                    content: `${myTitle} | ${site.siteMetadata.titleSuffix}`,
                },
                {
                    property: `og:image`,
                    content: myImage,
                },
                {
                    property: `og:description`,
                    content: myDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `description`,
                    content: myDescription,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: "@logemann",
                },
                {
                    name: `twitter:title`,
                    content: `${myTitle} | ${site.siteMetadata.titleSuffix}`,
                },
                {
                    name: `twitter:description`,
                    content: myDescription,
                },
                {
                    name: `twitter:site`,
                    content: "@okaycloud1",
                },
                {
                    name: `twitter:image`,
                    content: myImage,
                },
            ].concat(meta)}
        >
            {children}
        </Helmet>
    )
}

SEO.defaultProps = {
    meta: [],
    link: [],
    description: ``,
    title: null,
    image: null,
}

SEO.propTypes = {
    description: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    link: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    image: PropTypes.string
}

export default SEO
