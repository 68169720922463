import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='okicon-location'/>
                            </div>
                            <div className="icon">
                                <i className='okicon-location'/>
                            </div>
                            <h3>Unsere Adresse</h3>
                            <p>Dirschauer Str. 12, 10245 Berlin, Germany</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='okicon-phone'/>
                            </div>
                            <div className="icon">
                                <i className='okicon-phone'/>
                            </div>
                            <h3>Kontakt</h3>
                            <p>Telefon: <a href="tel:+493034045675">+49 (0)30 - 4045675</a></p>
                            <p>E-mail: <a href="mailto:info@okaycloud.de">info@okaycloud.de</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='okicon-clock'/>
                            </div>
                            <div className="icon">
                                <i className='okicon-clock'/>
                            </div>
                            <h3>Arbeitszeiten</h3>
                            <p>Montag - Freitag: 09:00 - 18:00</p>
                            <p>Samstag & Sonntag: auf Anfrage</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo