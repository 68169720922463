import React, {useState, useEffect} from 'react'
import contact from '../../assets/images/contact/undraw_contact_us_15o2.svg'
import {navigate} from 'gatsby'
import {useForm} from "react-hook-form";


const ContactForm = () => {
    const {register, handleSubmit, errors} = useForm({
        mode: "onBlur"
    })

    useEffect(() => {
        if (errors) {
            console.log(errors);
        }
    }, [errors]);
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    const [value, setValue] = useState({
        FULLNAME: '',
        EMAIL: '',
        MESSAGE: '',
        PHONE: ''
    });


    const handleServerResponse = async (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: {ok, msg}
        });

        form.reset();
        setValue({
            FULLNAME: '',
            EMAIL: '',
            MESSAGE: '',
            PHONE: ''
        })

        if (ok) {
            await navigate(form.getAttribute('action'));
        } else {
            await navigate("/error");
        }
    };

    function encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        const form = e.target;
        const formData = encode({
            "form-name": e.target.getAttribute("name"),
            ...data
        });
        setServerState({submitting: true});
        fetch('/', {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: formData
        })
            .then(res => {
                handleServerResponse(true, "Thanks for your message!", form);
            })
            .catch(err => {
                handleServerResponse(false, err.response.data.error, form);
            });
    }

    const isErrors = Object.keys(errors).length !== 0 && true;
    const onChangeHandler = e => {
        setValue({...value, [e.target.name]: e.target.value})
    }

    return (
        <section className="contact-area pb-100 pt-100">
            <div className="container">
                <div className="section-title" style={{marginTop: "20px"}}>
                    <h1>Bereit zum Durchstarten?</h1>
                    <p>Deine Emailadresse wird nicht publiziert. Erforderliche Felder haben einen Stern (*)</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form name="contactForm"
                                  action={"/success"}
                                  data-netlify="true"
                                  onSubmit={handleSubmit(onSubmit)}
                                  method={"POST"}
                                  encType={"application/x-www-form-urlencoded"}>
                                <input type="hidden" name="form-name" value="contactForm"/>
                                <p style={{display: 'none'}}>
                                    <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
                                </p>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className={`form-group ${(isErrors && errors.FULLNAME) ? 'has-error' : ''} ${value.message ? 'has-value' : ''}`}>
                                            <input type="text" name="FULLNAME"
                                                   onChange={onChangeHandler}
                                                   className="form-control"
                                                   placeholder={"Dein Name*"}
                                                   ref={register({
                                                       required: 'Full Name Required',
                                                   })}
                                            />
                                            {errors.FULLNAME && <span className="error">{errors.FULLNAME.message}</span>}
                                        </div>

                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className={`form-group ${(isErrors && errors.EMAIL) ? 'has-error' : ''} ${value.message ? 'has-value' : ''}`}>
                                            <input type="email" name="EMAIL"
                                                   onChange={onChangeHandler}
                                                   className="form-control"
                                                   placeholder={"Deine EMailadresse*"}
                                                   ref={register({
                                                       required: 'Email Required',
                                                   })}/>
                                            {errors.EMAIL && <span className="error">{errors.EMAIL.message}</span>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className={`form-group ${(isErrors && errors.PHONE) ? 'has-error' : ''} ${value.message ? 'has-value' : ''}`}>
                                            <input type="text" name="PHONE"
                                                   onChange={onChangeHandler}
                                                   className="form-control"
                                                   placeholder={"Deine Telefonnummer"}
                                                   />
                                            {errors.PHONE && <span className="error">{errors.PHONE.message}</span>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className={`form-group ${(isErrors && errors.MESSAGE) ? 'has-error' : ''} ${value.message ? 'has-value' : ''}`}>
                                            <textarea name="MESSAGE"
                                                      onChange={onChangeHandler}
                                                      className="form-control" cols="30" rows="6"
                                                      placeholder={"Schreibe Deine Nachricht...*"}
                                                      ref={register({
                                                          required: 'Message Required',
                                                      })}/>
                                            {errors.MESSAGE && <span className="error">{errors.MESSAGE.message}</span>}
                                        </div>
                                    </div>

                                    <div data-netlify-recaptcha="true"></div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn" disabled={serverState.submitting}>
                                            <i className="okicon-check"/>
                                            Nachricht senden
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm
