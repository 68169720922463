import React from 'react'
import PopularPostsWidget from "./widgets/PopularPostsWidget";
import CategoriesWidget from "./widgets/CategoriesWidget";
import AllTagsWidget from "./widgets/AllTagsWidget";
import SearchWidget from "./widgets/SearchWidget";

const BlogSidebar = ({tagCountsArray, categoryCountsArray, withSearch = false}) => {
    return (
        <div className="widget-area">
            {withSearch && <SearchWidget/>}
            <PopularPostsWidget/>
            <CategoriesWidget categoriesCountArray={categoryCountsArray}/>
            <AllTagsWidget tagsCountsArray={tagCountsArray}/>
        </div>
    )
}

export default BlogSidebar