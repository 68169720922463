import React from 'react'
import Logo from "../../assets/images/svg/okaycloud_logo.svg"
import {Link, useStaticQuery, graphql} from 'gatsby';
import {Helmet} from "react-helmet";
import {Row, Col, Container} from 'react-bootstrap'
import Img from 'gatsby-image'

const Footer = ({showMenu = true}) => {

    const data = useStaticQuery(
        graphql`
      query footerImagesQuery {
          map: file(relativePath: {eq: "footer-map.png"}) {
            childImageSharp {
              fluid(maxWidth: 690) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
         }
      }`
    );

    const currentYear = new Date().getFullYear();

    return (
        <>
            <Helmet>
                <style type="text/css">
                    {`
                        #color_logo_transparent2 {
                           fill: #eee
                        }
                    `}
                </style>
            </Helmet>
            <footer className="footer-area bg-color" style={!showMenu ? {paddingTop: "0"} : {}}>
                <Container>
                    {showMenu && <Row>
                        <Col lg={3} sm={6} className={"d-none d-xl-block"}>
                            <div className="single-footer-widget">
                                <a href="/" className="logo">
                                    <Logo/>
                                </a>

                                <ul className="social-link">
                                    {/*<li>
                                        <a href="https://linkedin.com/company/okaycloud" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='okicon-xing-square'/>
                                        </a>
                                    </li>*/}
                                    <li>
                                        <a href="https://linkedin.com/company/okaycloud" className="d-block"
                                           target="_blank" rel="noreferrer">
                                            <i className='okicon-linkedin-square'/>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='okicon-facebook-square'/>
                                        </a>
                                    </li>*/}
                                    <li>
                                        <a href="https://twitter.com/okaycloud1" className="d-block" target="_blank"
                                           rel="noreferrer">
                                            <i className='okicon-twitter-square'/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={3} xs={6}>
                            <div className="single-footer-widget">
                                <h3>Entdecken</h3>

                                <ul className="footer-links-list">
                                    <li>
                                        <Link to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/ueber-uns/">
                                            Über uns
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cloud-consulting/">
                                            Cloud Consulting
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/poc-erstellung/">
                                            POC Erstellung
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/kontakt/">
                                            Kontakt
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={3} xs={6}>
                            <div className="single-footer-widget">
                                <h3>Publikationen</h3>

                                <ul className="footer-links-list">
                                    <li>
                                        <a href={"https://medium.com/@logemann"}>Übersicht</a>
                                    </li>
                                    <li>
                                        <a href={"https://medium.com/saas-startup-factory/remote-logging-with-flutter-on-the-logz-io-elk-stack-f360df143ef0"}>
                                            Remote Logging with Flutter with logz.io
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://medium.com/aws-factory/continuous-delivery-of-a-java-spring-boot-webapp-with-aws-cdk-codepipeline-and-docker-56e045812bd2"}>
                                            Cont. Delivery on AWS
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://medium.com/aws-factory/schedule-your-aws-client-vpn-endpoint-and-reduce-costs-f68d8729bade"}>
                                            AWS Client VPN Endpoints
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={3} xs={6}>
                            <div className="single-footer-widget">
                                <h3>Addresse</h3>

                                <ul className="footer-contact-info">
                                    <li>
                                        okaycloud UG
                                    </li>
                                    <li>
                                        <i className='okicon-location-1'/>
                                        Dirschauer Str. 12
                                    </li>
                                    <li>
                                        10245 Berlin
                                    </li>
                                    <li>
                                        <i className='okicon-envelope-o'/>
                                        <a href="mailto:info@okaycloud.de">info@okaycloud.de</a>
                                    </li>
                                    <li>
                                        <i className='okicon-phone'/>
                                        <a href="tel:+493034045675">+49 (0)30 340 4567 5</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    }

                    <div className="footer-bottom-area">
                        <Row className="align-items-center">
                            <Col lg={6} md={6}>
                                <p>Copyright @{currentYear} <strong>okaycloud UG</strong> All rights reserved.</p>
                            </Col>

                            <Col lg={6} md={6}>
                                <ul>
                                    <li>
                                        <Link to="/datenschutz/">
                                            Datenschutz
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/impressum/">
                                            Impressum
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Container>

                <div className="footer-map">
                    <Img fluid={data.map.childImageSharp.fluid} alt={"World Map"}/>
                </div>
            </footer>
        </>
    );
}

export default Footer;
