import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import JamstackIntro from "../components/jamstack-webseiten/JamstackIntro";
import SEO from '../components/App/SEO'
import MoreThanBeautifulImages from "../components/jamstack-webseiten/MoreThanBeautifulImages";
import PerfectDeployment from "../components/jamstack-webseiten/PerfectDeployment";

const PocCreation = () => {
    return (
        <Layout>
            <SEO title={"Wir erstellen ultramoderne Jamstack Webseiten"}
                 description={"Wir entwickeln SEO optimierte Websiten auf Basis von Jamstack Frameworks. Dabei sind unsere erstellen so optimal konstruiert, das Google uns immer liebt."}
                 image={"/images/og/jamstack-og.png"}
            />
            <Navbar/>
            <JamstackIntro/>
            <MoreThanBeautifulImages/>
            <PerfectDeployment/>
            <Footer/>
        </Layout>
    )
}


export default PocCreation
