import React from 'react'
import image from '../../assets/images/poc-creation/undraw_statistic_chart_38b6.svg'
import {Row, Col} from 'react-bootstrap'

const SpreadRiskSection = () => {
    return (
        <section className="about-area pb-100 pt-70">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Risiko verteilen okaycloud einbeziehen</h2>
                                <p>
                                    Du willst Deinen Proof-of-Concept zeitnah bei Investoren oder Geschäftspartnern
                                    vorstellen. Wir helfen Dir dabei dieses Ziel zu erreichen, indem wir unser Wissen
                                    im Bereich Cloudentwicklung einbringen.
                                </p>
                                <p>
                                    Wir müssen keine Technologien oder Frameworks erlernen, sondern sind direkt auf
                                    Geschwindigkeit. Setze den Blinker links und fahren mit uns auf der
                                    Überholspur.
                                </p>

                                {/*<ul className="about-list">
                                    <li>
                                        <i className="okicon-check"></i>
                                        Big Data
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Visualization
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Warehousing
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Management
                                        <img src={shape2} alt="about" />
                                    </li>
                                </ul>*/}
                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}


export default SpreadRiskSection;
