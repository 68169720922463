import React from 'react'
import security from '../../assets/images/poc-creation/undraw_dev_productivity_umsq.svg'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import {Row, Col} from 'react-bootstrap'

const ExpertKnowledgeSection = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={6} xs={12}>
                        <div className="about-image">
                            <img src={security} alt="about"/>
                        </div>
                    </Col>

                    <Col lg={6} xs={12}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Zeit sparen durch unsere Expertise in der Cloud</h2>
                                <p>
                                    Du willst Deinen Proof-of-Concept zeitnah bei Investoren oder Geschäftspartnern
                                    vorstellen. Wir helfen Dir dabei dieses Ziel zu erreichen, indem wir unser Wissen
                                    im Bereich Cloudentwicklung einbringen.
                                </p>
                                <p>
                                    Wir müssen keine Technologien oder Frameworks erlernen, sondern sind direkt auf
                                    Geschwindigkeit. Setze den Blinker links und fahren mit uns auf der
                                    Überholspur.
                                </p>

                                {/*<ul className="about-list">
                                    <li>
                                        <i className="okicon-check"></i>
                                        Big Data
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Visualization
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Warehousing
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Management
                                        <img src={shape2} alt="about" />
                                    </li>
                                </ul>*/}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="about"/>
            </div>
        </section>
    )
}

export default ExpertKnowledgeSection;
