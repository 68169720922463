import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import Testimonials from "../components/Index/Testimonials"
import rocket from '../assets/images/main-banner/undraw_to_the_moon_v1mv.svg'
import StartProject from "../components/Index/StartProject";
import SEO from "../components/App/SEO";
import BlogSection from "../components/BlogContent/BlogSection";
import {graphql} from "gatsby";


const Home = ({data}) => {
    return (
        <Layout>
            <SEO
                 description={"Okaycloud sind die Spezialisten aus Berlin für Softwareprojekte in der Cloud. Wir beraten zu richtigen Architekturen und übernehmen bei Bedarf die Entwicklung."}
            />
            <Navbar/>
            <Banner headerImage={rocket}
                    mainText={"Wir helfen Dir Deine Produkte und Services in die Cloud zu verlagern. Dabei analysieren und bewerten wir Deinen Softwarestack und zeigen neue Nutzungsmöglichkeiten im Bereich Serverless Computing auf. Mit unserer Leidenschaft für Performance-Marketing erstellen wir auch die digitale Infrastruktur für moderne Jamstack Projekte."}
                    mainTitle={"Deine Software in der Cloud"}/>
            <OurSolutions/>
            <hr/>
            <BlogSection lastPosts={data.allMarkdownRemark.edges}/>
            <Testimonials/>
            <StartProject/>
            <Footer/>
        </Layout>
    )
}

export default Home;

export const pageQuery = graphql`
  query last3BlogPosts {
     allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: {disabled: {eq: false} } }
        limit: 3
      ) 
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 400)
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 750) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                authorFull {
                    name
                    authorimage {
                        childImageSharp {
                            fixed(width: 40) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
              }
            }
        }
     }
  }
`
