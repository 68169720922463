import React from 'react'
import image from '../../assets/images/about/undraw_usability_testing_2xs4.svg'
import {Row, Col} from 'react-bootstrap'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                        <div className="how-its-work-content">
                            <h2>Warum sind wir die Richtigen?</h2>
                            <p>
                                Ein Grund der über allem schwebt möchten wir, noch vor den Aufzählungen weiter unten,
                                los werden. Wir sind die Richtigen weil wir zuhören.
                            </p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Build vs. Buy Entscheidungen</h3>
                                    <p>Wir sind Profis wenn es darum geht zu eruieren ob man eine Komponente selbst
                                        entwickelt, oder besser auf einen vorhanden Service in der Cloud zurückgreift.
                                        Wir kennen alle relvanten SaaS Dienstleister und prüfen in welchen Fällen ein
                                        Zukauf Sinn macht.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Wir wissen wie ein Produkt aussehen muss</h3>
                                    <p>Denn wir haben schon viele in Eigenregie entwickelt und in den Markt gebracht.
                                        Wir denken in Produkten und nicht in Aufträgen. Wir wollen das Dein Produkt die
                                        nächste grosse SaaS Nummer wird.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Wir arbeiten agil und transparent</h3>
                                    <p>Du willst berechenbare Leistungen und Deadlines und wir liefern mit Hilfe von
                                        agilen Methoden wie Scrum genau das. Du bist als Product Owner jeden Tag Teil
                                        des Teams und sehen jeden Fortschritt und eventuelle Herausforderungen
                                        sofort.</p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="how-its-work-image">
                            <img src={image} alt="banner"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default WhyChooseUs
