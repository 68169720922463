import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import ArchitectureSection from '../components/SaasConcepts/ArchitectureSection'
import SecuritySection from '../components/SaasConcepts/SecuritySection'
import FrontendSection from '../components/SaasConcepts/FrontendSection'
import MoreConsultingAreas from '../components/SaasConcepts/MoreConsultingAreas'
import SEO from '../components/App/SEO'

const CloudConsulting = () => {
    return (
        <Layout>
            <SEO title={"Cloud Consulting - SaaS Konzepte und mehr"}
                 description={"Im Bereich des Cloud Consulting beraten wie zu Themen wie SaaS Projektanforderungen. Von Multi-Tenancy über resiliente Infrastukturen."}
                 image={"/images/og/cloud-consulting-og.png"}
            />
            <Navbar />
            <ArchitectureSection />
            <SecuritySection />
            <FrontendSection />
            <MoreConsultingAreas />
            <Footer />
        </Layout>
    )
}

export default CloudConsulting
