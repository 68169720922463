import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/App/Banner"
import fourZeroFour from '../assets/images/404.svg'
import {Helmet} from "react-helmet";

const Error = ({location}) => {
    return (
        <Layout>
            <Helmet>
                <title>Ooops. Wir haben die Seite nicht gefunden.</title>
                <meta name={"description"} content={`Die angeforderte Seite '${location.pathname}' existiert nicht oder ist fehlerhaft.`}/>
            </Helmet>
            <Navbar/>
            <Banner headerImage={fourZeroFour} mainText={`Die angeforderte Seite '${location.pathname}' existiert nicht oder ist fehlerhaft.`}
                    mainTitle={"Ooops. Wir haben die Seite nicht gefunden."} />
            <Footer/>
        </Layout>
    )
}

export default Error
