import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import DataAnalysis from "../components/seo/DataAnalysis";
import SEO from '../components/App/SEO'
import OnlineMarketing from "../components/seo/OnlineMarketing";
import SeoIntro from "../components/seo/SeoIntro";

const PocCreation = () => {
    return (
        <Layout>
            <SEO title={"Search Engine Optimization und Search Engine Marketing"}
                 description={"Wir fokussieren auf ein gutes Ranking und helfen Ihnen rund um das Marketing im Bereich Suchmaschinen."}
                 image={"/images/og/seo-sem-og.png"}
            />
            <Navbar/>
            <SeoIntro/>
            <OnlineMarketing/>
            <DataAnalysis/>
            <Footer/>
        </Layout>
    )
}


export default PocCreation
