import React from 'react'
import image from '../../assets/images/saas-concepts/undraw_design_notes_8dmv.svg'
import {Row, Col} from 'react-bootstrap'

const FrontendSection = () => {
    return (
        <section className="about-area pb-100 pt-70">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Das Frontend im SaaS</h2>
                                <p>
                                    SaaS Businesses haben andere Anforderungen an die Technologie als onPremise
                                    Software, die primär von Nutzern innerhalb eines Konzerns genutzt wird. Lokale
                                    Installationen mit definierten Nutzermengen haben geringere Skalierungsanforderungen
                                    und selten Herausforderungen im Bereich Bandbreite.
                                </p>
                                <p>
                                    Die Wahl der Frontendtechnologie ist aus Architektursicht sehr entscheidend. Eine
                                    falsche Wahl kann sowohl die Kosten dramatisch erhöhen, als auch die Skalierbarkeit
                                    beeinträchtigen. Darüberhinaus ist die Benutzerakzeptanz im SaaS wichtiger, da man
                                    im Markt bestehen muss. Wir beraten Dich welche Technologien für SaaS in Frage
                                    kommen.
                                </p>

                                {/*<ul className="about-list">
                                    <li>
                                        <i className="okicon-check"></i>
                                        Big Data
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Visualization
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Warehousing
                                        <img src={shape2} alt="about" />
                                    </li>
                                    <li>
                                        <i className="okicon-check"></i>
                                        Data Management
                                        <img src={shape2} alt="about" />
                                    </li>
                                </ul>*/}

                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default FrontendSection;
