import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Idea2DevelopmentSection from '../components/PocCreation/Idea2DevelopmentSection'
import ExpertKnowledgeSection from '../components/PocCreation/ExpertKnowledgeSection'
import SpreadRiskSection from '../components/PocCreation/SpreadRiskSection'
import SEO from '../components/App/SEO'


const PocErstellung = () => {
    return (
        <Layout>
            <SEO title={"Erstellung von Proof-of-Concepts / Prototypen"}
                 description={"Wir erstellen mit unseren Experten Deinen PoC-of-Concept. Wir bringen große Erfahrung aus dem Bereich der Produktentwicklung in der Cloud mit."}
                 image={"/images/og/poc-creation-og.png"}
            />
            <Navbar />
            <Idea2DevelopmentSection />
            <ExpertKnowledgeSection />
            <SpreadRiskSection />
            <Footer />
        </Layout>
    )
}

export default PocErstellung
