import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import SEO from '../components/App/SEO'

const Kontakt = () => {
    return (
        <Layout>
            <SEO title={"Dein direkter Kontakt zu uns"}
                 description={"Es gibt verschiedene Wege okaycloud zu kontakieren. Der einfachste ist über das Kontaktformular, welches am Ende der Kontaktseite gefunden werden kann."}
                 image={"/images/og/kontakt-og.png"}
            />
            <Navbar />

            <ContactForm />
            <ContactInfo />
            <Footer />
        </Layout>
    );
}

export default Kontakt
