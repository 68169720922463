import React from 'react'
import image from '../../assets/images/jamstack/undraw_heatmap_uyye.svg'


const MoreTeanBeatifulImagesSection = () => {
    return (
        <section className="about-area pb-5 pt-5">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={image} alt="about"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Optimierung bis ins Detail - Mehr als schöne Bilder</h2>
                                <p>
                                    Viele Agenturen denken das es ausreicht eine schöne Seite zu entwerfen, jedoch
                                    unterscheidet Google nicht danach, wie schön eine Seite ist. Natürlich sollte der
                                    Benutzer nicht abgeschreckt sein, wenn er die Seite besucht, aber wir sind der festen
                                    Überzeugung das Konversion wichtiger ist als ein blinkender Button.
                                </p>
                                <p>
                                    Wir erstellen Webprojekte wo alles optimiert wird: Bilder, CSS Code, Ladezeiten im Allgemeinen
                                    und dynamisches Javascript im Speziellen. Wir sorgen dafür das Formulare konvertieren und
                                    geben Ihnen Tools an die Hand um das Konvertieren zu messen. Wir wollen das Sie mehr
                                    Produkte oder Dienstleistungen verkaufen. Wir wollen Ihren Umsatz steigern und nicht nur
                                    eine schöne Webseite erstellen.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default MoreTeanBeatifulImagesSection;
