import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.png'
import client2 from '../../assets/images/testimonials/client2.png'
import client3 from '../../assets/images/testimonials/client3.png'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'

const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='okicon-arrow-left'></i>",
        "<i class='okicon-arrow-right'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial"/>
                        Testimonials
                    </span>
                    <h2>Was unsere Kunden sagen!</h2>
                </div>

                {display ? <OwlCarousel
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-testimonials-item">
                        <p>
                            Unsere Produktinfrastruktur ist uns genauso wichtig wie unsere Anwendung selbst. Okaycloud
                            hat uns Ideen für beide Bereiche gegeben und Lösungsansätze für eine Migration zu AWS
                            gegeben. Wir hoffen auf weiterhin gute Zusammenarbeit und produktiven Austausch.
                        </p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial"/>
                                <div className="title">
                                    <h3>Clemens Vest</h3>
                                    <span>CEO bei Preishoheit.de</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Okaycloud berät uns im Bereich SaaS Architektur, Online
                        Marketing und SEO. Wir schätzen das hochprofessionelle Arbeiten und das
                        tiefe Fachwissen in den genannten Themenbereichen. Gerade die lange Erfahrung
                        bei Softwareprojekten in der Cloud haben uns überzeugt.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial"/>
                                <div className="title">
                                    <h3>Annelie Kleist</h3>
                                    <span>CEO bei coming-home.com</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>
                            Okaycloud berät uns im Bereich SaaS Entwicklung und Cloud deployments unserer onPremise
                            Logistiksoftware. Die Lösungen die uns vorgeschlagen werden sind dabei immer sehr innovativ
                            und zeugen von einem tiefen Verstädnis moderner serverless Anwendungen.
                        </p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client3} alt="testimonial"/>
                                <div className="title">
                                    <h3>Stefan Hennek</h3>
                                    <span>CEO bei LOGENTIS GmbH</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}

                {/*<div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i>
                        Check Out All Reviews <span></span>
                    </Link>
                </div>*/}
            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial"/>
            </div>
        </div>
    )
}

export default Testimonials;
