import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/saas-concepts/undraw_detailed_analysis_xn7y.svg'
import {Row, Col} from 'react-bootstrap'

const ArchitectureSection = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Von OnPremis in die Cloud</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Cloud Consulting im Bereich SaaS und Transaktionale Systeme</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Die Architektur von SaaS Business Anwendungen ist in der Regel stark unterschiedlich
                                    zu onPremise Software im Inhousegebrauch. Falls jedoch eine bestehende Software mit
                                    möglichst wenig Änderungen an der Bestandsarchitektur als SaaS betrieben werden
                                    soll, so gibt es auch hier sinnvolle Strategien.
                                </p>

                                <p>
                                    Wir zeigen Dir die verschiedenen Varianten auf und kommunizieren die inhärenten
                                    Vor- und Nachteile der jeweiligen Option. Dabei bedeutet Cloud Consulting für uns auch
                                    die Beratung welche Cloudinfrastrukturen für die aktuelle Aufgabe in Frage kommen.
                                </p>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ArchitectureSection
