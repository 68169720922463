import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import {graphql} from "gatsby";
import EbookFeature from "../components/landing/EbookFeature";
import StartProject from "../components/common/StartProject";
import Img from 'gatsby-image'

const UeberUns = ({data, location: {origin}}) => {
    //const siteUrl = data.site.siteMetadata.siteUrl;
    return (
        <Layout>
            <SEO title={"Gratis Mini E-Book | Six essentials Skills for bootstrapping a startup"}
                 description={"Dieses gratis E-Book gibt Dir Hinweise und Tipps welche Skills nötig sind um ein Startup zu bootstrappen. Da auf den Sologründer sehr viele Aufgaben auf einmal zukommen und es noch kein Team gibt, ist es wichtig zu wissen was man beherschen muss."}
                 image={"/images/og/six-skills-ebook.png"}
            >
            </SEO>
            <Navbar showMenu={false}/>
            <EbookFeature image={<Img fluid={data.ebookImage.childImageSharp.fluid}/>}/>
            <StartProject header={"Marc Logemann"}
                          content={"Marc, founder of okaycloud, is a veteran in the software development space. Bootstrapped and sold his logistics software company within the last 15 years. He is busy learning new stuff everyday and getting new things off the ground."}
                          button={"Mehr über okaycloud"}
                          image={<Img fluid={data.profileImage.childImageSharp.fluid} alt={"Profile Image"}/>}
            />
            <Footer showMenu={false}/>
        </Layout>
    )
}

export default UeberUns;

export const pageQuery = graphql`
query
{
    site
    {
        siteMetadata
        {
            siteUrl
        }
    }
    profileImage: file(relativePath: {eq: "landing/profile_logemann.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
     }
    ebookImage: file(relativePath: {eq: "landing/6skills-ebook-cover.png"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
     }            
}
`;

