import React, {Fragment} from 'react';

const CategoriesWidget = ({categoriesCountArray}) => {
    return (
        <div className="widget widget_categories">
            <h3 className="widget-title">Categories</h3>
            <ul>
                {categoriesCountArray.map((categoryCount) => (
                    <Fragment key={categoryCount.category}>

                        {/*<Link key={categoryCount.category} to="#" className={"neutrl-link-optional-color"}>*/}
                            <i className='okicon-ionic' style={{marginRight: "5px"}}/>
                            {categoryCount.category}
                            <span style={{marginRight: "15px"}}
                                  className="tag-link-count"> ({categoryCount.posts})</span>
                        {/*</Link>*/}
                        <br/>
                    </Fragment>
                ))}
            </ul>
        </div>
    );
};

export default CategoriesWidget;
