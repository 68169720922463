import React from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import StartupSolutionsSVG from '../../assets/images/svg/rocket.svg'
import SaaSSolutionsSVG from '../../assets/images/svg/computer.svg'
import TransactionalSolutionsSVG from '../../assets/images/svg/money.svg'

const OurSolutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <Container>
                <div className="section-title">
                    <h2>Wir entwickeln moderne Cloudsoftware</h2>
                </div>

                <Row>
                    <Col lg={4} sm={6}>
                        <div className="single-solutions-box">
                            <StartupSolutionsSVG  width={"180px"}/>
                            <h3>
                                Startup Anwendungen
                            </h3>
                            <p>
                                Anwendungen in der Early-Stage haben spezielle Anforderungen Geschwindigkeit und
                                Skalierung bei gleichzeitigem Blick auf die Kosten. Wir helfen Dir dabei.
                            </p>
                        </div>
                    </Col>

                    <Col lg={4} sm={6}>
                        <div className="single-solutions-box">
                            <div className="icon">
                                <SaaSSolutionsSVG  width={"200px"}/>
                            </div>

                            <h3>
                                SaaS Lösungen
                            </h3>

                            <p>
                                Software-as-a-Service Anwendungen sind der grosse Wachstumsmarkt der letzten 5 Jahre.
                                Unsere Expertise im Front- und Backend erlaubt uns erstklassige Konzepte zu erstellen.
                            </p>

                        </div>
                    </Col>

                    <Col lg={{span: 4}} sm={{span: 6}}>
                        <div className="single-solutions-box">
                            <div className="icon">
                                <TransactionalSolutionsSVG width={"200px"} style={{marginTop: "60px"}}/>
                            </div>

                            <h3>
                                Transaktions-Platformen
                            </h3>

                            <p>
                                Systeme mit starken transakionalem Background haben spezielle Anforderungen an die
                                Robustheit und Ausfallsicherheit. Wir planen die passenden Architekturen.
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OurSolutions;
