import React from 'react'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import ProjectVisionSection from '../components/AwsConsulting/ProjectVisionSection'
import DatabaseFileSection from '../components/AwsConsulting/DatabaseFileSection'
import ServerlessSection from '../components/AwsConsulting/ServerlessSection'
import MoreAwsAreas from '../components/AwsConsulting/MoreAwsAreas'
import StartProject from "../components/common/StartProject";
import SEO from '../components/App/SEO'

const AwsConsulting = ({data}) => {
    return (
        <Layout>
            <SEO title={"Amazon AWS Consulting - Mehr als nur Beratung"}
                 description={"Steige mit uns in die Welt des Serverless Computing mit AWS ein. Wir machen Dich fit für die Cloud und zeigen Architekturoptionen für die Projekte der Zukunft."}
                 image={`/images/og/aws-consulting-og.png`}
            />
            <Navbar/>
            {/*<MainBanner title={"Amazon AWS Consulting Header"}
                        subtitle={"Amazon AWS Consulting Subheader"}
                        image={"aws-consulting/undraw_server_cluster_jwwq.svg"}/>*/}
            <ProjectVisionSection/>
            <DatabaseFileSection/>
            <ServerlessSection/>
            <StartProject header={"Erlebe eine neue Dimension in der AWS Beratung"}
                          content={"Wir erstellen nich nur Konzepte, sondern setzen diese auch um. Miss uns nicht an der Menge an Papier, sondern am Resultat: Ihrer Software in der Cloud!"}
                          button={"Mit uns und AWS durchstarten"}/>
            <MoreAwsAreas/>
            <Footer/>
        </Layout>
    )
}

export default AwsConsulting
