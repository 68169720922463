import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/jamstack/undraw_responsive_6c8s.svg'
import {Row, Col} from 'react-bootstrap'

const JamstackIntro = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Gatsby - NextJS - Jamstack</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Wir erstellen Marketing Webseiten im Jamstack Prinzip</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Wir erstellen auf Basis von elegant designten Templates die perfekte Marketing
                                    Webseite. Dabei arbeiten wir methodischer als die klassische Agentur von nebenan.
                                    Wir nutzen Gatsby als statischen Sitegenerator und nutzen CDN basierte
                                    Infrastrukturen für das Deployment.
                                </p>
                                <p>
                                    Wir sind keine Agentur und denken auch nicht wie eine. Wir sind Softwareentwickler
                                    und wissen wie eine Webseite technisch deployed sein muss, damit diese weltweit
                                    performt. Und das wir optisch mithalten können, siehst Du an unserer Seite.
                                </p>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default JamstackIntro
