import React, {useEffect} from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/thankyou/six-skills-ebook-dl.png'
import {Link} from 'gatsby';
import {Row, Col} from 'react-bootstrap'



const SixSkillsEbook = () => {

    useEffect(
        () => {
            let timer1 = setTimeout(() => {
                    //window.location = "https://okaycloud.de/dl/6-essential-Skills-ebook.pdf";
                    window.open("https://okaycloud.de/dl/6-essential-Skills-ebook.pdf", '_blank');
                }
            , 2 * 1000);
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );


    return (
        <div className="seo-agency-banner" style={{paddingTop: "8rem"}}>
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">six essential skills for bootstrapping a SaaS startup</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Danke für den Download und viel Spaß beim Lesen</h1>
                                <p>
                                    Wenn der Download nicht automatisch
                                    beginnt. <a href={"https://okaycloud.de/dl/6-essential-Skills-ebook.pdf"} download={"w3logo"}>Hier</a> klicken.

                                </p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/blog/" className="default-btn">
                                        <i className="okicon-arrow-right"/>
                                        Mehr Lust auf Lesen? Hier geht es zum Blog
                                        <span/>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SixSkillsEbook
