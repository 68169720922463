import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/growth-hacking/undraw_growth_curve_8mqx.svg'
import {Row, Col} from 'react-bootstrap'

const GrowthHackingIntro = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">WACHSTUM</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Wir helfen Dir mit Growth Hacking Techniken</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Wachstum generieren ist das Ziel vieler Unternehmen, wobei die genaue Definition wo
                                    Wachstum entstehen soll, je nach Situation unterschiedlich ist. Growth Hacking verbindet
                                    die Disziplinen Softwareentwicklung, Marketing und Produkt miteinander. Im besten Fall ist
                                    ein Grwoth Hacker also jemand der technisch versiert ist, ein Gespühr für Marketing hat und
                                    schnell iterieren kann.
                                </p>
                                <p>
                                    Wir helfen Ihnen mit Techniken wie z.B. A/B Tests, Email-Marketing, Search Engine Marketing oder
                                    Content Marketing Erfolge zu erzielen. WIr helfen Ihnen mit neuen Formaten und Ideen
                                    zu experimentieren, denn beim Growth Hacking ist Scheitern Teil des Spiels. Gute Growth Hacks
                                    haben in der Regel eine kurze Haltbarkeit, sind aber in der Zeit sehr effektiv, da sie teilweise
                                    einzigartig am Markt sind.
                                </p>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image p-5">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}


export default GrowthHackingIntro
