import React from 'react'
import showFacts from '../../assets/images/saas-concepts/showfacts.svg'

const MoreAwsAreas = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <h2>Weitere AWS Bereiche</h2>
                            <p>In denen wir Dich unterstützen:</p>

                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Identity Management</h3>
                                    <p>Authentifizierung and Authorisierung mit AWS Cognito™ und OAuth2 mit IdentitPools.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Messaging & Pub/Sub</h3>
                                    <p>Erstellung robuster Microservice Umgebungen mit Amazon SNS und SQS.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>EC2 Computing</h3>
                                    <p>Server in der Cloud via Amazon EC2™ mit Netzwerkfeatures wie Load-Balancing und automatischer Replikation</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Bilderkennung</h3>
                                    <p>Automatische Mustererkennung und Textextrahierung als Service mit Amazon Rekognition™.
                                        Rekognition.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Website Hosting</h3>
                                    <p>Kostenoptimiertes Bereitstellen und Hosten auf weltweit verteilten Knoten mit Amazon S3 und Cloudfront.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        #
                                    </div>
                                    <h3>Infrastructure as Code (IaC)</h3>
                                    <p>Sichere Reproduktion Ihrer Cloud Infrastruktur via Amazon CDK™ und dem Serverless Framework.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={showFacts} alt="choose"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MoreAwsAreas
