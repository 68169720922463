import React from 'react'
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO'
import {graphql} from "gatsby";
import SixSkillsEbook from "../../components/thankyou/SixSkillsEbook";

const UeberUns = ({data, location: {origin}}) => {
    const siteUrl = data.site.siteMetadata.siteUrl;
    return (
        <Layout>
            <SEO title={"Danke für das Herunterladen des Six Skills Ebooks."}
                 description={"Wir hoffen Dir bringt der Inhalt des Mini E-Books '6 essential skills for bootrapping a SaaS startup' Freude."}
                 image={"/images/og/ueber-uns-og.png"}
            />

            <Navbar/>
            <SixSkillsEbook/>
            <Footer/>
        </Layout>
    )
}

export default UeberUns;

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        siteUrl
      }
    }
}
`;

