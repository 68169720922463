import React from 'react'
import image from '../../assets/images/jamstack/undraw_uploading_go67.svg'
import {Row, Col} from 'react-bootstrap'

const PerfectDeployment = () => {
    return (
        <section className="about-area pb-100 pt-70 bg-f1f8fb">
            <div className="container-fluid">
                <Row className="row align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="about-content">
                            <div className="content">
                                <h2>Das perfekte Deployment</h2>
                                <p>
                                    Wir sind der festen Überzeugung das Wordpress die schlechteste Wahl für
                                    ein Webprojekt ist. Es ist langsamer, unsicherer, teurer bei den Hostingkosten und
                                    fühlt sich mangels Single-Page Fähigkeiten an wie 2010. Jamstack ist der neue Weg.
                                    Wir erklären Ihnen gerne genauer warum es so viel besser.
                                </p>
                                <p>
                                    Mit Projekten auf Jamstack Basis müssen Sie keineN Datenbankserver im Betrieb haben.
                                    Sie brauchen nur sehr einfache Serverinfrastruktur, welche viele Hoster via CDNs
                                    weltweit verteilen können zu einem Bruchteil der Kosten, die eine Wordpress Installation
                                    benötigt. Da es am Ende nur HTML Dateien mit ein wenig CSS und Javascript ist, bekommen
                                    Jamstackprojekte regelmässig bestnoten in Sachen Seitengeschwindigkeit. Ihre Nutzer
                                    und der Google Crawler werden es Ihnen danken.

                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <div className="about-image p-4">
                            <img src={image} alt="about"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default PerfectDeployment;
