import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/about/aboutusberlin.jpg'
import {Link} from 'gatsby';
import {Row, Col} from 'react-bootstrap'

const AboutSection = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 1}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <span className="sub-title">Die okaycloud Story</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Über uns</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    <b>
                                        Zu Hause in Berlin, genaugenommen in Friedrichshain, will okaycloud seinen Kunden die Vorteile der Cloud im
                                        Allgemeinen und von Amazon AWS™ und Google Cloud im Speziellen näherbringen um gemeinsam
                                        erfolgreiche Projekte auf den neuen Plattformen zu gestalten.
                                    </b>
                                </p>
                                <p>
                                    Gegründet von Marc Logemann, Inhaber und Geschäftsführer der LOGENTIS GmbH, einem
                                    Independent Software Vendor für Logistiksoftware, möchte okaycloud Firmen helfen den
                                    Weg in die Cloud zu beschreiten. Aus den Erfahrungen des Gründers und seines Teams
                                    mit Cloudmigrationen von onPremise Software konnten wertvolle Erfahrungen gesammelt
                                    werden, die nun Kunden zur Verfügung gestellt werden. Unser Netzwerk von erfahrenen
                                    SaaS Experten im Startup Umfeld gibt uns die Möglichkeit technische Konzeptionen für
                                    Subscription oder Pay-per-Use getriebene Businessmodelle zu erstellen und zu
                                    implementieren.

                                </p>
                                <p>
                                    Wir beraten nicht nur im Bereich Amazon AWS, sondern nutzen selbst für viele unserer
                                    eigenen Projekte die Infrastruktur von Amazon. Natürlich unter anderem auch diese
                                    Webseite.
                                </p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/ueber-uns/" className="default-btn">
                                        <i className="okicon-arrow-right"/>
                                        Über uns
                                        <span/>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AboutSection
