import React from 'react'
import ReactWOW from 'react-wow'
import image from '../../assets/images/aws-consulting/aws-services.svg'
import {Row, Col} from 'react-bootstrap'

const ProjectVisionSection = () => {
    return (
        <div className="seo-agency-banner">
            <div className="container-fluid">
                <Row className="align-items-center">
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}>
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Amazon AWS Consulting aus Berlin mit Expertise aus vielen Projekten</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>
                                    Du willst mit Amazon Web Services (AWS) durchstarten damit Dein Cloudprojekt ein
                                    Erfolg wird? Wir sind AWS Partner, technisch zertifiziert und haben das Know-How Ihr
                                    Projekt positiv herausstechen zu lasen. Dabei schauen wir genau auf die Ziele und
                                    erarrbeiten mit Dir ein Konzept für die Nutzung von AWS.
                                </p>
                                <p>
                                    Amazon Web Services besteht aus mehr als 175 Produkten und Services. Ohne
                                    langjährige Erfahrung sowohl mit AWS, als auch mit Cloudprojekten ist es schwer bis
                                    unmöglich die passenden Bausteine auszuwählen. Auch auf der Kostenseite schlägt sich
                                    eine falsche Auswahl negativ nieder. Lasse Dir gemeinsam mit uns Klarheit schaffen und
                                    das Bestmögliche Setup finden.
                                </p>
                                <p>Erlebe bahnbrechendes AWS Consulting mit okaycloud.</p>
                            </ReactWOW>

                            {/*<div>
                                TODO: Auflistung 4 Fragen
                            </div>*/}
                        </div>
                    </Col>
                    <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 1}}>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={image} alt="banner"/>
                            </div>
                        </ReactWOW>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ProjectVisionSection
